import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Header from '../components/header';
import { SystemDataContext } from '../context/SystemDataContext';
import LoadingComponent from '../components/loading-component';

const ProtectedRouteEmployee = ({ component: Component, title, ...rest }) => {
    const { isAuth, isEmployee } = useContext(AuthContext);
    const { fetchAllData, isLoadingSystemData } = useContext(SystemDataContext);

    useEffect(
        function () {
            fetchAllData();
        },
        [isAuth]
    );

    return (
        <div className="h-full min-h-screen bg-gray-100">
            <Header title={title} />
            <main className="flex justify-center pt-36 mx-2">
                <Route
                    render={(props) =>
                        isAuth && isEmployee() ? (
                            isLoadingSystemData ? (
                                <LoadingComponent />
                            ) : (
                                <Component {...props} />
                            )
                        ) : (
                            <Redirect to="/login" />
                        )
                    }
                    {...rest}
                />
            </main>
        </div>
    );
};

export default ProtectedRouteEmployee;
