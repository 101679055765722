import axios from 'axios';
import { BASE_URL, LOCAL_STORAGE_TOKEN_PATH } from '../../constants';

const AuthAPI = {
    login: (loginCredentials) => {
        const requestUrl = `${BASE_URL}/login`;

        return axios.post(requestUrl, null, {
            auth: {
                username: loginCredentials.email,
                password: loginCredentials.password,
            },
        });
    },

    refresh: () => {
        const requestUrl = `${BASE_URL}/token/refresh`;

        return axios.post(requestUrl, {
            token: localStorage.getItem(LOCAL_STORAGE_TOKEN_PATH),
        });
    },

    check: () => {
        const requestUrl = `${BASE_URL}/token/check`;

        return axios.post(requestUrl, {
            token: localStorage.getItem(LOCAL_STORAGE_TOKEN_PATH),
        });
    },

    reset: (payload) => {
        const requestUrl = `${BASE_URL}/password/reset`;

        return axios.post(requestUrl, payload);
    },

    forgot: (payload) => {
        const requestUrl = `${BASE_URL}/password/forgot`;

        return axios.post(requestUrl, payload);
    },
};

export default AuthAPI;
