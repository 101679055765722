import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../../../../components/button/ButtonGroup';
import InputDateSet from '../../../../components/form/InputDateSet';
import ButtonTertiary from '../../../../components/button/ButtonTertiary';
import Label from '../../../../components/form/parts/Label';
import SelectSet from '../../../../components/form/SelectSet';
import InputTimeSet from '../../../../components/form/InputTimeSet';
import TextAreaSet from '../../../../components/form/TextAreaSet';
import InputTextSet from '../../../../components/form/InputTextSet';
import getValidationSchema from './getValidationSchema';
import { SystemDataContext } from '../../../../context/SystemDataContext';
import getValueOfOptionLabel from '../../../../helpers/getValueOfOptionLabel';

function EmployeeHourLogForm({ initialValues, handleSubmit }) {
    const [
        typeOfWorkAmountIsRequired,
        setTypeOfWorkAmountIsRequired,
    ] = useState(initialValues.typeOfWork?.specifyAmount || false);
    const { locations, productGroups, typeOfWorks } = useContext(
        SystemDataContext
    );

    const { t, i18n } = useTranslation();
    let history = useHistory();

    const valueOfOptionLabel = getValueOfOptionLabel(i18n.language);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() =>
                getValidationSchema(typeOfWorkAmountIsRequired, t)
            }
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                if (!values.typeOfWorkAmount) {
                    delete values.typeOfWorkAmount;
                }

                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, setFieldValue, values, errors }) => {
                const filteredTypeOfWorks = typeOfWorks.filter(
                    (item) =>
                        item.productGroup?.id === values.productGroupId &&
                        item.active
                );

                return (
                    <Form>
                        <InputDateSet
                            label={t('hour_log.label_date')}
                            name="date"
                            locale={i18n.language}
                        />

                        {/* START Specific input for setting location id */}
                        <div className="mb-3">
                            <Label>{t('hour_log.label_location')}</Label>
                            <div className="w-full flex space-x-8">
                                {locations.map(({ id, description }) => (
                                    <button
                                        key={id}
                                        className={`btn ${
                                            values.locationId === id
                                                ? 'bg-primary-400 hover:bg-primary-600 text-white'
                                                : 'bg-gray-200 hover:bg-gray-400 text-gray-600'
                                        } / text-lg px-4 py-2 rounded-xl`}
                                        type={'button'}
                                        onClick={() =>
                                            setFieldValue('locationId', id)
                                        }
                                    >
                                        {description}
                                    </button>
                                ))}
                            </div>
                        </div>
                        {/* END Specific input for setting location id */}

                        <SelectSet
                            label={t('hour_log.label_product_group')}
                            name={'productGroupId'}
                            options={productGroups.filter(
                                (item) => item.active
                            )}
                            isClearable={false}
                            isSearchable={false}
                            valueLabel={valueOfOptionLabel}
                            onChangeExtraAction={() => {
                                setFieldValue('typeOfWorkId', '');
                                setTypeOfWorkAmountIsRequired(false);
                            }}
                        />

                        <SelectSet
                            label={t('hour_log.label_type_of_work')}
                            name={'typeOfWorkId'}
                            options={filteredTypeOfWorks}
                            onChangeExtraAction={(option) =>
                                setTypeOfWorkAmountIsRequired(
                                    option.specifyAmount
                                )
                            }
                            isClearable={false}
                            isSearchable={false}
                            valueLabel={valueOfOptionLabel}
                        />

                        {typeOfWorkAmountIsRequired ? (
                            <InputTextSet
                                label={t('hour_log.label_type_of_work_amount')}
                                name="typeOfWorkAmount"
                            />
                        ) : null}

                        <InputTimeSet
                            label={t('hour_log.label_amount')}
                            name={'amount'}
                        />

                        <TextAreaSet
                            label={t('hour_log.label_description')}
                            name={'description'}
                        />

                        <ButtonGroup>
                            <ButtonTertiary onClick={() => history.goBack()}>
                                {t('button.cancel')}
                            </ButtonTertiary>
                            <ButtonPrimary
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                type={'submit'}
                            >
                                {t('button.save')}
                            </ButtonPrimary>
                        </ButtonGroup>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default EmployeeHourLogForm;
