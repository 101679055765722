import React, { useEffect, useState } from 'react';
import moment from 'moment';
import fileDownload from 'js-file-download';
import WrapperDefaultManager from '../../../../components/wrapper-default/manager';
import HourLogOverviewToolbar from './toolbar';
import HourLogOverviewTable from './Table';
import useGetHourLogEmployeesWeekOverviewData from '../../../../hooks/useGetHourLogEmployeesWeekOverviewData';
import LoadingComponent from '../../../../components/loading-component';
import HourLogAPI from '../../../../api/hour-log/HourLogAPI';
import ButtonSecondary from '../../../../components/button/ButtonSecondary';
import Notification from "../../../../components/notification";
import { DATE_FORMAT_WEEK, DATE_FORMAT_YEAR } from '../../../../constants';
import getFileNameExportHourLogs from '../../../../helpers/getFileNameExportHourLogs';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';

function HourLogOverview() {
    const [filterDate, setFilterDate] = useState(() => {
        const saved = localStorage.getItem('hourLogFilterDate');
        const initialValue = JSON.parse(saved);
        return initialValue ? moment(initialValue) : moment();
    });
    const [reviewHours, setReviewHours] = useState(false);
    const [reviewItems, setReviewItems] = useState([]);
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        localStorage.setItem(
            'hourLogFilterDate',
            JSON.stringify(filterDate.format('YYYY-MM-DD'))
        );
    }, [filterDate]);

    const {
        data,
        loadData,
        isLoading,
        notificationFetch,
    } = useGetHourLogEmployeesWeekOverviewData(filterDate);

    if (isLoading) {
        return <LoadingComponent />;
    }

    function toggleReviewHours() {
        setReviewHours(!reviewHours);
    }

    function exportToCsv() {
        HourLogAPI.exportToCsv(
            filterDate.format(DATE_FORMAT_YEAR),
            filterDate.format(DATE_FORMAT_WEEK)
        )
            .then((payload) => {
                fileDownload(payload.data, getFileNameExportHourLogs());
            })
            .catch((error) => {
                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het exporteren. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    function denyReviewItems() {
        let employeeIds = reviewItems
            .filter((item) => item.checked)
            .map((item) => item.id);

        HourLogAPI.rejectEmployeesDateRange(
            employeeIds,
            filterDate.format('YYYY-MM-DD')
        )
            .then((payload) => {
                loadData();
                setNotification({ message: `Uren zijn succesvol afgekeurd`, variant: 'success', visible: true, error: {} });
            })
            .catch((error) => {
                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het afkeuren van de uren. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    function approveReviewItems() {
        let employeeIds = reviewItems
            .filter((item) => item.checked)
            .map((item) => item.id);

        HourLogAPI.approveEmployeesDateRange(
            employeeIds,
            filterDate.format('YYYY-MM-DD')
        )
            .then((payload) => {
                loadData();
                setNotification({ message: `Uren zijn succesvol goedgekeurd`, variant: 'success', visible: true, error: {} });
            })
            .catch((error) => {
                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het goedkeuren van de uren. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <WrapperDefaultManager>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                              onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <HourLogOverviewToolbar
                filterDate={filterDate}
                setFilterDate={setFilterDate}
            />
            <div className="flex justify-between">
                {reviewHours ? (
                    <div>
                        <ButtonPrimary
                            variant={'danger'}
                            onClick={() => denyReviewItems()}
                        >
                            Uren afkeuren
                        </ButtonPrimary>
                        &nbsp;
                        <ButtonPrimary
                            variant={'success'}
                            onClick={() => approveReviewItems()}
                        >
                            Uren goedkeuren
                        </ButtonPrimary>
                    </div>
                ) : (
                    <div />
                )}
                <div>
                    <ButtonSecondary onClick={toggleReviewHours}>
                        Uren beoordelen
                    </ButtonSecondary>
                    &nbsp;
                    <ButtonSecondary onClick={exportToCsv}>
                        Exporteer uren
                    </ButtonSecondary>
                </div>
            </div>
            <HourLogOverviewTable
                data={data}
                filterDate={filterDate}
                reviewHours={reviewHours}
                setReviewItems={setReviewItems}
            />
        </WrapperDefaultManager>
    );
}

export default HourLogOverview;
