import React from 'react';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import WrapperDefaultManager from '../../../components/wrapper-default/manager';

function Dashboard({ history }) {
    return (
        <WrapperDefaultManager showBackButton={false}>
            <h1>Dit is de dashboard pagina voor de manager rol</h1>
            <small>
                Medewerkers kunnen deze pagina niet zien. Verder invulling van
                deze pagina volgt.
            </small>
            <div className="flex space-x-2 my-2">
                <ButtonPrimary onClick={() => history.push('/overzicht-uren')}>
                    Overzicht uren
                </ButtonPrimary>
                <ButtonPrimary onClick={() => history.push('/medewerkers')}>
                    Medewerkers
                </ButtonPrimary>
            </div>
        </WrapperDefaultManager>
    );
}

export default Dashboard;
