import React, { useState, useEffect } from 'react';
import WrapperDefaultManager from '../../../../../components/wrapper-default/manager';
import ButtonPrimary from '../../../../../components/button/ButtonPrimary';
import useGetLocationListData from '../../../../../hooks/useGetLocationListData';
import LoadingComponent from '../../../../../components/loading-component';
import LocationListTable from './Table';
import Notification from "../../../../../components/notification";
import LocationNew from './new';
import LocationEdit from './edit';
import LocationDelete from './delete';

function LocationList() {
    const {
        data,
        isLoading,
        notificationFetch,
        addItem,
        updateItem,
        deleteItem,
    } = useGetLocationListData();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    function openEditLocationModal(productGroup) {
        setSelectedLocation(productGroup);
        setShowEditModal(true);
    }

    function openDeleteLocationModal(productGroup) {
        setSelectedLocation(productGroup);
        setShowDeleteModal(true);
    }

    return (
        <WrapperDefaultManager>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                       onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <div className="flex justify-end">
                <ButtonPrimary onClick={() => setShowNewModal(true)}>
                    Nieuw
                </ButtonPrimary>
            </div>

            <LocationListTable
                data={data}
                openEditLocationModal={openEditLocationModal}
                openDeleteLocationModal={openDeleteLocationModal}
            />

            {showNewModal && (
                <LocationNew
                    open={showNewModal}
                    setOpen={setShowNewModal}
                    addItem={addItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showEditModal && (
                <LocationEdit
                    open={showEditModal}
                    setOpen={setShowEditModal}
                    selectedLocation={selectedLocation}
                    updateItem={updateItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showDeleteModal && (
                <LocationDelete
                    open={showDeleteModal}
                    setOpen={setShowDeleteModal}
                    selectedLocation={selectedLocation}
                    deleteItem={deleteItem}
                    setNotificationParent={setNotification}
                />
            )}
        </WrapperDefaultManager>
    );
}

export default LocationList;
