import { BASE_URL, LOCAL_STORAGE_TOKEN_PATH } from '../../constants';
import AuthAPI from '../auth/AuthAPI';
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
});

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers.Authorization =
        'Bearer ' + localStorage.getItem(LOCAL_STORAGE_TOKEN_PATH);
    return config;
});

axiosInstance.interceptors.response.use(null, (error) => {
    if (
        error.config &&
        error.response?.status === 401 && // Use the status code your server returns when token has expired
        !error.config.__isRetry
    ) {
        return new Promise((resolve, reject) => {
            refreshToken(axios, error.config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    return Promise.reject(error);
});

const refreshToken = (axios, config) => {
    return new Promise((resolve, reject) => {
        AuthAPI.refresh() // Endpoint to request new token
            .then((payload) => {
                localStorage.setItem(
                    LOCAL_STORAGE_TOKEN_PATH,
                    payload.data.token
                ); // Store new token locally (Local storage or cookies)
                config.headers.Authorization = 'Bearer ' + payload.data.token; // Attach the new token to the headers
                axios
                    .request(config) // Repeat the initial request
                    .then((result) => {
                        return resolve(result);
                    })
                    .catch((err) => {
                        console.log(err);
                        return reject(err);
                    });
            })
            .catch((err) => {
                localStorage.removeItem(LOCAL_STORAGE_TOKEN_PATH);
                window.location.reload();
            });
    });
};

export default axiosInstance;
