const employeeMenuItems = [
    {
        link: '/',
        label: 'menu.overview',
    },
    {
        link: '/employee/hourlog/new',
        label: 'menu.new',
    },
    {
        link: '/logout',
        label: 'menu.logout',
    },
];

export default employeeMenuItems;
