import React from 'react';
import InputTextSet from '../../../../../../components/form/InputTextSet';

function ProductGroupForm() {
    return (
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet label={'Code'} name="code" required />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet
                    label={'Omschrijving'}
                    name="description"
                    required
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet
                    label={'Omschrijving Engels'}
                    name="descriptionEn"
                    required
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet
                    label={'Omschrijving Pools'}
                    name="descriptionPl"
                    required
                />
            </div>
        </div>
    );
}

export default ProductGroupForm;
