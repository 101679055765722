import axiosInstance from '../default-setup/AxiosInstance';

export default {
    index: function () {
        const requestUrl = 'managers';

        return axiosInstance.get(requestUrl);
    },

    create: function (payload) {
        const requestUrl = 'managers';

        return axiosInstance.post(requestUrl, payload);
    },

    update: function (payload) {
        const requestUrl = `managers/${payload.id}`;

        return axiosInstance.put(requestUrl, payload);
    },

    enable: function (id) {
        const requestUrl = `managers/${id}/enable`;

        return axiosInstance.patch(requestUrl);
    },

    disable: function (id) {
        const requestUrl = `managers/${id}/disable`;

        return axiosInstance.patch(requestUrl);
    },
};
