import { AuthProvider } from './context/AuthContext';
import RouteSwitch from './route/RouteSwitch';
import { SystemDataProvider } from './context/SystemDataContext';

function App() {
    return (
        <AuthProvider>
            <SystemDataProvider>
                <RouteSwitch />
            </SystemDataProvider>
        </AuthProvider>
    );
}

export default App;
