import React, { useContext, useState } from 'react';
import LogoImage from '../../../images/Logo_Darthuizer_Groep.svg';
import LoginForm from './Form';
import { useTranslation } from 'react-i18next';
import AuthAPI from '../../../api/auth/AuthAPI';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import ErrorMessage from '../../../components/form/parts/ErrorMessage';

function Login({ location }) {
    const [error, setError] = useState('');
    const [redirectToReferrer, toggleRedirect] = useState(false);
    let { from } = location.state || { from: { pathname: '/' } };
    const { login } = useContext(AuthContext);
    const { t } = useTranslation();

    function handleSubmit(values, setSubmittingFinished) {
        AuthAPI.login(values)
            .then((payload) => {
                setError('');
                setSubmittingFinished();
                login(payload.data.token, () => toggleRedirect(true));
            })
            .catch((error) => {
                if (error.response && error.response.status) {
                    setError(error.response.data.message);
                } else {
                    setError(t('general.something_went_wrong_on_server'));
                }
                setSubmittingFinished();
            });
    }

    if (redirectToReferrer) return <Redirect to={from} />;

    return (
        <div className="w-full h-screen flex justify-center items-center sm:bg-default-background">
            <div className="w-full sm:w-2/3 md:w-128 h-full bg-white sm:h-auto sm:rounded-lg sm:shadow-2xl p-4 sm:p-8 py-8">
                <div className="flex flex-col items-center">
                    <img src={LogoImage} alt="Logo Darthuizer Groep" />
                    <div className="w-full max-w-sm my-8">
                        <h3 className="mb-4 font-semibold text-md sm:text-lg">
                            {t('authentication.login.login_by')}
                        </h3>
                        <LoginForm handleSubmit={handleSubmit} />
                        <ErrorMessage>{error}</ErrorMessage>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
