import React from 'react';
import { ClipLoader } from 'react-spinners';

function ButtonSecondary(props) {
    let variantStyle = '';

    switch (props.variant) {
        case 'danger':
            variantStyle =
                'border-red-600 text-red-600 hover:bg-red-700 hover:text-white disabled:opacity-60';
            break;
        case 'success':
            variantStyle =
                'border-green-600 text-green-600 hover:bg-green-700 hover:text-white disabled:opacity-60';
            break;
        case 'primary':
        default:
            variantStyle =
                'border-primary-400 text-primary-400 hover:bg-primary-600 hover:text-white disabled:opacity-60';
            break;
    }

    return (
        <button
            className={`btn max-h-8 ${variantStyle} text-md px-3 py-1 rounded border ${
                props.disabled && 'cursor-not-allowed'
            }`}
            type={'button'}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}{' '}
            {props.isLoading ? <ClipLoader color={'white'} size={17} /> : null}
        </button>
    );
}

ButtonSecondary.defaultProps = {
    variant: 'primary',
    isLoading: false,
};

export default ButtonSecondary;
