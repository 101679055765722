import * as Yup from 'yup';

function getValidationSchemaManager() {
    return Yup.object({
        name: Yup.string().required('Verplicht'),
        email: Yup.string().email('Ongeldig e-mailadres').required('Verplicht'),
    });
}

export default getValidationSchemaManager;
