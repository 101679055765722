import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function DropdownMenu({ label, subItems }) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="inline-flex justify-center content-center w-full px-2 sm:px-3 py-1 text-sm">
                            <span className="text-xs sm:text-base">
                                {label}
                            </span>
                            <FaChevronDown
                                className="w-3 h-3 m-1 ml-2 -mr-1 hidden sm:block"
                                size={12}
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 w-56 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {subItems.map((item, index) => (
                                <Link to={item.link}>
                                    <Menu.Item
                                        key={index}
                                        as="div"
                                        className="m-1 p-1 rounded text-gray-500 hover:bg-gray-200 hover:text-gray-600"
                                    >
                                        {item.label}
                                    </Menu.Item>
                                </Link>
                            ))}
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
}

export default DropdownMenu;
