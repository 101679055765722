import React, {useState} from 'react';
import Modal from '../../../../../../../components/modal';
import Notification from "../../../../../../../components/notification";
import HourLogAPI from '../../../../../../../api/hour-log/HourLogAPI';

function HourLogDelete({ id, date, setOpen, open, deleteItem, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    function handleSubmit() {
        HourLogAPI.delete(id)
            .then(() => {
                deleteItem(date, id);
                // show notification (deleted successfully!)
                setNotificationParent({ message: `Urenlog is verwijderd`, variant: 'success', visible: true, error: {} });
                setOpen(false);

                setOpen(false);
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het verwijderen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <Modal
            title={'Uren log verwijderen'}
            open={open}
            setOpen={setOpen}
            confirmAction={handleSubmit}
            confirmText={'Verwijderen'}
            variant={'danger'}
        >
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                              onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}
            <p className="mb-4">
                Weet u zeker dat urenlog van{' '}
                <strong>{date.format('DD-MM-YYYY')}</strong> (
                <strong>id {id}</strong>) verwijderd kan worden?
            </p>
        </Modal>
    );
}

export default HourLogDelete;
