import React from 'react';

function Thead({ headerGroups }) {
    return (
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th
                            {...column.getHeaderProps()}
                            className="font-semibold text-left border-b border-gray-200 p-2"
                            width={column.width}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                    <th className="border-b border-gray-200" width={'6%'}>
                        &nbsp;
                    </th>
                </tr>
            ))}
        </thead>
    );
}

export default Thead;
