import React, {useEffect, useState} from 'react';
import WrapperDefaultManager from '../../../../components/wrapper-default/manager';
import Label from '../../../../components/form/parts/Label';
import HourLogEmployeeDayAccordion from './day-accordion';
import LoadingComponent from '../../../../components/loading-component';
import useGetHourLogsEmployeeWeekOverviewData from '../../../../hooks/useGetHourLogsEmployeeWeekOverviewData';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';
import presenterHourLogStatus from '../../../../helpers/presenterHourLogStatus';
import HourLogAPI from '../../../../api/hour-log/HourLogAPI';
import fileDownload from 'js-file-download';
import ButtonSecondary from '../../../../components/button/ButtonSecondary';
import Notification from "../../../../components/notification";
import getFileNameExportHourLogs from '../../../../helpers/getFileNameExportHourLogs';
import presenterTotalHours from '../../../../helpers/presenterTotalHours';

function HourLogEmployee({ match: { params } }) {
    const {
        data,
        isLoading,
        notificationFetch,
        approveHours,
        rejectHours,
        updateOrCreate,
        createEmptyHourLog,
        deleteHourLog,
    } = useGetHourLogsEmployeeWeekOverviewData(
        params.employeeId,
        params.week,
        params.year
    );

    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    const {
        employee: { name, totalScheduleHours, employmentAgency },
        details: { status, total },
        week,
    } = data;

    function exportToCsv() {
        HourLogAPI.exportToCsvPerEmployee(
            params.employeeId,
            params.year,
            params.week
        )
            .then((payload) => {
                fileDownload(payload.data, getFileNameExportHourLogs(name));
            })
            .catch((error) => {

                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het exporteren. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <WrapperDefaultManager>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                              onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <div className="flex">
                <div className="flex flex-wrap w-2/3 space-y-2">
                    <div className="px-2 w-full md:w-1/2 lg:w-1/3">
                        <Label>Naam</Label>
                        <div>{name}</div>
                    </div>
                    <div className="px-2 w-full md:w-1/2 lg:w-1/3">
                        <Label>Week</Label>
                        <div>{`${params.week}-${params.year}`}</div>
                    </div>
                    <div className="px-2 w-full md:w-1/2 lg:w-1/3">
                        <Label>Standaard werkuren</Label>
                        <div>{totalScheduleHours}</div>
                    </div>
                    <div className="px-2 w-full md:w-1/2 lg:w-1/3">
                        <Label>Uitzendbureau</Label>
                        <div>{employmentAgency}</div>
                    </div>
                    <div className="px-2 w-full md:w-1/2 lg:w-1/3">
                        <Label>Status</Label>
                        <div>{presenterHourLogStatus(status)}</div>
                    </div>
                    <div className="px-2 w-full md:w-1/2 lg:w-1/3">
                        <Label>Totaal uren</Label>
                        <div>
                            {presenterTotalHours(total, totalScheduleHours)}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end w-1/3 space-y-2">
                    <ButtonSecondary
                        onClick={exportToCsv}
                        disabled={total === 0}
                    >
                        Exporteer uren
                    </ButtonSecondary>
                </div>
            </div>

            {week.map((item) => (
                <HourLogEmployeeDayAccordion
                    key={item.date}
                    data={item}
                    createEmptyHourLog={createEmptyHourLog}
                    deleteItem={deleteHourLog}
                    updateOrCreate={updateOrCreate}
                    employeeId={data.employee.id}
                    setNotificationParent={setNotification}
                />
            ))}

            <div className="flex justify-end space-x-4 my-4">
                <ButtonPrimary
                    variant={'danger'}
                    onClick={rejectHours}
                    disabled={total === 0}
                >
                    Uren afkeuren
                </ButtonPrimary>
                <ButtonPrimary
                    variant={'success'}
                    onClick={approveHours}
                    disabled={total === 0}
                >
                    Uren goedkeuren
                </ButtonPrimary>
            </div>
        </WrapperDefaultManager>
    );
}

export default HourLogEmployee;
