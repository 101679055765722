import React, {useState} from 'react';
import Modal from '../../../../../components/modal';
import EmployeeAPI from '../../../../../api/employee/EmployeeAPI';
import Notification from "../../../../../components/notification";

function EmployeeDelete({ selectedEmployee, setOpen, open, deleteItem, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    function handleSubmit() {
        EmployeeAPI.delete(selectedEmployee.id)
            .then(() => {
                deleteItem(selectedEmployee.id);

                // show notification (deleted successfully!)
                setNotificationParent({ message: `Medewerker  ${selectedEmployee.name} is verwijderd`, variant: 'success', visible: true, error: {} });
                setOpen(false);
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het verwijderen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <Modal
            title={'Medewerker verwijderen'}
            open={open}
            setOpen={setOpen}
            confirmAction={handleSubmit}
            confirmText={'Verwijderen'}
            variant={'danger'}
        >
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                              onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}
            <p className="mb-4">
                Weet u zeker, dat medewerker{' '}
                <strong>{selectedEmployee.name}</strong> verwijderd kan worden.
            </p>
            <p>
                Gekoppelde gegevens, zoals geschreven uren, zijn niet meer
                zichtbaar.
            </p>
        </Modal>
    );
}

export default EmployeeDelete;
