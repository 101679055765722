import { Formik } from 'formik';
import React, {useState} from 'react';
import Modal from '../../../../../components/modal';
import ManagerAPI from '../../../../../api/manager/ManagerAPI';
import ManagerForm from '../default-form';
import moment from 'moment';
import { DATE_FORMAT_BACKEND } from '../../../../../constants';
import getValidationSchemaManager from '../default-form/getValidationSchemaManager';
import Notification from "../../../../../components/notification";

const initialValues = {
    date: moment().format(DATE_FORMAT_BACKEND),
    name: '',
    email: '',
};

function ManagerNew({ addItem, setOpen, open, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    function handleSubmit(values, setSubmittingDone) {
        ManagerAPI.create(values)
            .then((payload) => {
                const managerData = payload.data.data;

                addItem({ ...managerData });

                // show notification (created successfully!)
                setNotificationParent({ message: `Manager ${managerData.name} is toegevoegd`, variant: 'success', visible: true, error: {} });

                setOpen(false);
            })
            .catch((error) => {
                // show notification (error!)
                setNotification({ message: `Fout bij toevoegen manager`, variant: 'error', visible: true, error: error });
            });
        setSubmittingDone();
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchemaManager()}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, handleSubmit, errors }) => {
                return (
                    <Modal
                        title={'Manager nieuw'}
                        open={open}
                        setOpen={setOpen}
                        confirmAction={handleSubmit}
                        confirmText={'Toevoegen'}
                        isLoading={isSubmitting}
                    >
                        {notification.visible && (
                            <Notification message={notification.message} variant={notification.variant} error={notification.error}
                                          onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
                        )}
                        <ManagerForm />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default ManagerNew;
