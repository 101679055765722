import React, { useEffect, useState } from 'react';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';
import useGetHourLogEmployeeListData from '../../../../hooks/useGetHourLogEmployeeListData';
import WrapperDefaultEmployee from '../../../../components/wrapper-default/employee';
import moment from 'moment';
import Label from '../../../../components/form/parts/Label';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../../../../components/loading-component';
import {
    DATE_FORMAT_DEFAULT_DISPLAY,
    HOURLOG_STATUS_APPROVED,
} from '../../../../constants';
import getValueOfOptionLabel from '../../../../helpers/getValueOfOptionLabel';
import EmployeeHourLogListToolbar from './Toolbar';
import Notification from "../../../../components/notification";

function EmployeeHourLogList({ history }) {
    const [filterDate, setFilterDate] = useState(() => {
        const saved = localStorage.getItem('hourLogFilterDate');
        const initialValue = JSON.parse(saved);
        return initialValue ? moment(initialValue) : moment();
    });
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        localStorage.setItem(
            'hourLogFilterDate',
            JSON.stringify(filterDate.format('YYYY-MM-DD'))
        );
    }, [filterDate]);

    const { t, i18n } = useTranslation();

    const { data, isLoading, notificationFetch } = useGetHourLogEmployeeListData(filterDate);

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);


    if (isLoading) {
        return <LoadingComponent />;
    }

    const valueOfOptionLabel = getValueOfOptionLabel(i18n.language);

    return (
        <div className="flex flex-col align-center space-y-4 w-full sm:w-120">
            <WrapperDefaultEmployee>
                {notification.visible && (
                    <Notification message={notification.message} variant={notification.variant} error={notification.error}
                                  onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
                )}

                <EmployeeHourLogListToolbar
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                />
                <div className="flex justify-between">
                    <div>
                        <Label>{t('hour_log.total_hours')}</Label>
                        <div>
                            {data?.reduce(
                                (total, { amount }) => total + amount,
                                0
                            )}
                        </div>
                    </div>
                    <ButtonPrimary
                        onClick={() => history.push('/employee/hourlog/new')}
                    >
                        {t('button.create')}
                    </ButtonPrimary>
                </div>
            </WrapperDefaultEmployee>

            {data.map(
                ({
                    id,
                    productGroup,
                    date,
                    typeOfWork,
                    typeOfWorkAmount,
                    amount,
                    status,
                }) => (
                    <WrapperDefaultEmployee key={id}>
                        <div className="flex flex-wrap justify-between space-y-1">
                            <div className="w-2/3">
                                <div>
                                    <Label>
                                        {t('hour_log.label_product_group')}
                                    </Label>
                                    <div>
                                        {productGroup?.[valueOfOptionLabel]}
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <div className="text-right">
                                    <Label>{t('hour_log.label_date')}</Label>
                                    <div>
                                        {moment(date).format(
                                            DATE_FORMAT_DEFAULT_DISPLAY
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-2/3">
                                <div>
                                    <Label>
                                        {t('hour_log.label_type_of_work')}
                                    </Label>
                                    <div>{typeOfWork[valueOfOptionLabel]}</div>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <div className="text-right">
                                    <Label>{t('hour_log.label_amount')}</Label>
                                    <div>{amount}</div>
                                </div>
                            </div>
                            <div className="w-2/3">
                                <Label>{t('hour_log.label_status')}</Label>
                                {t('hour_log.status_' + status.toLowerCase())}
                            </div>
                            <div className="w-1/3 text-right content-end self-end">
                                <Label>
                                    {t('hour_log.label_type_of_work_amount')}
                                </Label>
                                <div>
                                    {typeOfWorkAmount ? typeOfWorkAmount : '-'}
                                </div>
                            </div>

                            <div className="w-full text-right self-end">
                                {status !== HOURLOG_STATUS_APPROVED && (
                                    <ButtonPrimary
                                        onClick={() =>
                                            history.push(
                                                `/employee/hourlog/edit/${id}`
                                            )
                                        }
                                    >
                                        {t('button.edit')}
                                    </ButtonPrimary>
                                )}
                            </div>
                        </div>
                    </WrapperDefaultEmployee>
                )
            )}
        </div>
    );
}

export default EmployeeHourLogList;
