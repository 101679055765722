import React, {useEffect} from 'react';

function Notification({ message, onClose, variant, error }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 5000); // Auto close after 3 seconds

        return () => {
            clearTimeout(timer);
        };
    }, [onClose]);

    let variantStyle = '';
    let errors = [];
    if (variant === 'error') {
        if (error && error.response && error.response.data && error.response.data.errors) {
            for (const item of error.response.data.errors) {
                switch (item.detail) {
                    case 'The email has already been taken.':
                    case 'Email address already in use.':
                        errors.push({message: 'Ingevoerde e-mail adres is al in gebruik', variant: 'warning'});
                        break;
                    case "You can't log more hours on this date.":
                    case 'It is not possible to log any more hours on this date.':
                        errors.push({message: 'Maximaal aantal uren op deze dag bereikt.', variant: 'warning'});
                        break;
                    case 'The code has already been taken.':
                    case 'ProductGroup code must be unique':
                    case 'TypeOfWork code must be unique':
                        errors.push({message: 'Ingevoerde code is al in gebruik.', variant: 'warning'});
                        break;
                    default:
                        errors.push({message: 'Er is iets misgegaan bij het opslaan van de gegevens. Probeer het nogmaals, of neem contact met ons op.', variant: 'error'});
                        break;
                }
            }
        } else {
            errors.push({message: '', variant: 'error'});
        }
    }

    function getVariantStyle(variant)  {
        switch (variant) {
            case 'success':
                return 'bg-green-100 text-green-600';
                break;
            case 'error':
                return 'bg-red-100 text-red-600';
                break;
            case 'warning':
                return 'bg-yellow-400 text-yellow-700';
                break;
            case 'info':
                return 'bg-blue-100 text-blue-600';
                break;
            default:
                return 'bg-white text-gray-600';
        }
    }



    if(variant === 'error'){
        return (
            <>
                {errors.map( error => {
                    variantStyle = getVariantStyle(error.variant);
                    return (
                    <div
                        className={`relative flex justify-between mb-4 p-4 shadow-xl rounded-lg ${variantStyle}`}
                    >
                        <div>
                            {message}<br />
                            {error.message}
                        </div>
                        <span onClick={onClose} className="absolute top-0 right-2 cursor-pointer"> x </span>
                    </div>);
                })}
            </>
        );

    } else {
        variantStyle = getVariantStyle(variant);
        return (
            <div
                className={`relative flex justify-between mb-4 p-4 shadow-xl rounded-lg ${variantStyle}`}
            >
                <div>
                    {message}
                </div>
                <span onClick={onClose} className="absolute top-0 right-2 cursor-pointer"> x </span>
            </div>
        );

    }
}

export default Notification;
