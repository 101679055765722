import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils, { parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import { FaRegCalendarAlt } from 'react-icons/fa';

const FilterDate = ({ filterDate, setFilterDate }) => {
    const [showDayPicker, setShowDayPicker] = useState(false);

    const handleDayChange = (date) => {
        setFilterDate(moment(date));
        setShowDayPicker(false);
    };

    return (
        <div className="relative">
            <div
                onClick={() => setShowDayPicker(!showDayPicker)}
                className="text-lg cursor-pointer"
            >
                <FaRegCalendarAlt />
            </div>
            {showDayPicker && (
                <div className="absolute w-72 left-0 top-6 bg-white shadow-lg rounded z-10">
                    <DayPicker
                        selectedDays={filterDate.toDate()}
                        parseDate={parseDate}
                        showWeekNumbers={true}
                        locale={'nl'}
                        firstDayOfWeek={1}
                        localeUtils={MomentLocaleUtils}
                        onDayClick={handleDayChange}
                    />
                </div>
            )}
        </div>
    );
};

export default FilterDate;
