import { useEffect, useReducer } from 'react';
import ManagerAPI from '../api/manager/ManagerAPI';

const INITIAL_STATE = {
    data: [],
    isLoading: false,
    notificationFetch: {message: '', variant: '', visible: false, error: {}}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'RESULT':
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case 'IS_LOADING':
            return { ...state, isLoading: action.payload };
        case 'ADD_ITEM':
            return { ...state, data: [...state.data, action.payload] };
        case 'UPDATE_ITEM':
            return {
                ...state,
                data: state.data.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        case 'DELETE_ITEM':
            return {
                ...state,
                data: state.data.filter((item) => item.id !== action.payload),
            };
        case 'NOTIFICATION_FETCH':
            return { ...state, notificationFetch: action.payload };
        default:
            return state;
    }
};

function useGetManagerListData() {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        setIsLoading(true);
        ManagerAPI.index()
            .then((payload) => {
                setResult(payload.data.data);
            })
            .catch((error) => {
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het ophalen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
                setIsLoading(false);
            });
    }, []);

    function setIsLoading(payload) {
        dispatch({
            type: 'IS_LOADING',
            payload: payload,
        });
    }

    function setResult(payload) {
        dispatch({
            type: 'RESULT',
            payload: payload,
        });
    }

    function addItem(payload) {
        dispatch({
            type: 'ADD_ITEM',
            payload: payload,
        });
    }

    function updateItem(payload) {
        dispatch({
            type: 'UPDATE_ITEM',
            payload: payload,
        });
    }

    function deleteItem(id) {
        dispatch({
            type: 'DELETE_ITEM',
            payload: id,
        });
    }

    function setNotificationFetch(payload) {
        dispatch({
            type: 'NOTIFICATION_FETCH',
            payload: payload,
        });
    }
    return {
        data: state.data,
        isLoading: state.isLoading,
        notificationFetch: state.notificationFetch,
        addItem,
        updateItem,
        deleteItem,
    };
}

export default useGetManagerListData;
