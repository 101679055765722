import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Header from '../components/header';
import { SystemDataContext } from '../context/SystemDataContext';
import LoadingComponent from '../components/loading-component';
import { useTranslation } from 'react-i18next';

const ProtectedRouteManager = ({ component: Component, title, ...rest }) => {
    const { isAuth, isManager } = useContext(AuthContext);
    const { fetchAllData, isLoadingSystemData } = useContext(SystemDataContext);
    const { i18n } = useTranslation();

    useEffect(
        function () {
            fetchAllData();
            i18n.changeLanguage('nl');
        },
        [isAuth]
    );

    return (
        <div className="h-full min-h-screen bg-gray-100">
            <Header title={title} />
            <div className="container mx-auto pt-36">
                <Route
                    render={(props) =>
                        isAuth && isManager() ? (
                            isLoadingSystemData ? (
                                <LoadingComponent />
                            ) : (
                                <Component {...props} />
                            )
                        ) : (
                            <Redirect to="/login" />
                        )
                    }
                    {...rest}
                />
            </div>
        </div>
    );
};

export default ProtectedRouteManager;
