import axiosInstance from '../default-setup/AxiosInstance';

export default {
    fetchApplicationVersion: function () {
        const requestUrl = `version`;

        return axiosInstance.get(requestUrl);
    },

    fetchEmploymentAgencies: function () {
        const requestUrl = `employment-agencies`;

        return axiosInstance.get(requestUrl);
    },

    fetchLocations: function () {
        const requestUrl = `locations`;

        return axiosInstance.get(requestUrl);
    },

    fetchProductGroups: function () {
        const requestUrl = `product-groups`;

        return axiosInstance.get(requestUrl);
    },

    fetchTypeOfWorks: function () {
        const requestUrl = `type-of-works`;

        return axiosInstance.get(requestUrl);
    },
};
