import React, { useState } from 'react';
import { useField } from 'formik';
import InputText from './InputText';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';

function InputPassword({ autoComplete = 'off', ...props }) {
    const [field] = useField(props);
    const [showPassword, toggleShowPassword] = useState(false);

    return (
        <div>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
                <InputText
                    {...field}
                    {...props}
                    id={props.id || field.name}
                    autoComplete={autoComplete}
                    type={showPassword ? 'text' : 'password'}
                />
                <span
                    onClick={() => toggleShowPassword(!showPassword)}
                    className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-2 py-2"
                >
                    {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                </span>
            </div>
        </div>
    );
}

export default InputPassword;
