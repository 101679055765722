import React, {useState} from 'react';
import Modal from '../../../../../../components/modal';
import Notification from "../../../../../../components/notification";
import LocationAPI from '../../../../../../api/location/LocationAPI';

function LocationDelete({ selectedLocation, setOpen, open, deleteItem, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    function handleSubmit() {
        LocationAPI.delete(selectedLocation.id)
            .then(() => {
                deleteItem(selectedLocation.id);

                // show notification (deleted successfully!)
                setNotificationParent({ message: `Locatie ${selectedLocation.description} is verwijderd`, variant: 'success', visible: true, error: {} });

                setOpen(false);
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het verwijderen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <Modal
            title={'Locatie verwijderen'}
            open={open}
            setOpen={setOpen}
            confirmAction={handleSubmit}
            confirmText={'Verwijderen'}
            variant={'danger'}
        >
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                              onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}
            <p className="mb-4">
                Weet u zeker, dat locatie{' '}
                <strong>{selectedLocation.description}</strong> verwijderd kan
                worden?
            </p>
            <p>
                Controleer of deze locatie <u>nergens</u> meer gebruikt wordt.
            </p>
        </Modal>
    );
}

export default LocationDelete;
