import React, { useContext } from 'react';
import { SystemDataContext } from '../../../../../../../context/SystemDataContext';
import { MdEdit } from 'react-icons/md';

function HourLogEmployeeTableRowView({
    productGroupId,
    typeOfWorkId,
    locationId,
    description,
    period,
    amount,
    typeOfWorkAmount,
    toggleShowEdit,
}) {
    const { locations, productGroups, typeOfWorks } = useContext(
        SystemDataContext
    );

    return (
        <tr className="tr-default hover:bg-yellow-50 border-gray-200 border-b">
            <td className="p-2">
                {productGroupId
                    ? productGroups.find((item) => item.id === productGroupId)
                          .description
                    : '-'}
            </td>
            <td className="p-2">
                {typeOfWorkId
                    ? typeOfWorks.find((item) => item.id === typeOfWorkId)
                          .description
                    : '-'}
            </td>
            <td className="p-2">
                {locationId
                    ? locations.find((item) => item.id === locationId)
                          .description
                    : '-'}
            </td>
            <td className="p-2">{description}</td>
            <td className="p-2">{period}</td>
            <td className="p-2">{amount}</td>

            <td className="p-2">{typeOfWorkAmount ? typeOfWorkAmount : '-'}</td>
            <td className="p-2">
                <div className="flex justify-end space-x-2 text-lg text-gray-500">
                    <MdEdit
                        title={'Bewerken'}
                        className="hover:text-gray-600 cursor-pointer"
                        onClick={toggleShowEdit}
                    />
                </div>
            </td>
        </tr>
    );
}

export default HourLogEmployeeTableRowView;
