import React from 'react';
import ReactSelect from 'react-select';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

function Select({
    options,
    valueKey,
    valueLabel,
    placeholder,
    onChangeExtraAction,
    id,
    disabled,
    isClearable,
    isSearchable,
    ...props
}) {
    const { t } = useTranslation();

    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            marginTop: '4px',
        }),
        menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        }),
        singleValue: (styles, { data }) => ({
            ...styles,
        }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    function sortOptions(a, b) {
        var optionA = a[valueLabel].toUpperCase();
        var optionB = b[valueLabel].toUpperCase();
        if (optionA < optionB) {
            return -1;
        }
        if (optionA > optionB) {
            return 1;
        }

        return 0;
    }

    return (
        <ReactSelect
            options={options.sort(sortOptions)}
            getOptionLabel={(option) => option[valueLabel]}
            getOptionValue={(option) => option[valueKey]}
            noOptionsMessage={() => t('form_general.select_no_options')}
            isClearable={isClearable}
            isSearchable={isSearchable}
            placeholder={
                placeholder
                    ? placeholder
                    : t('form_general.select_empty_placeholder')
            }
            onChange={(option) => {
                option
                    ? setFieldValue(field.name, option[valueKey])
                    : setFieldValue(field.name, null);
                onChangeExtraAction(option);
            }}
            className={field.name}
            data-id={field.name}
            isDisabled={disabled}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            value={
                options
                    ? field.value
                        ? options.find(
                              (option) => option[valueKey] === field.value
                          )
                        : ''
                    : ''
            }
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    // primary25: themeApp.colors.greyLight,
                    // primary: themeApp.colors.primaryDark,
                },
                spacing: {
                    ...theme.spacing,
                    baseUnit: 2,
                    controlHeight: 24,
                    menuGutter: 4,
                },
            })}
            styles={customStyles}
        />
    );
}

Select.defaultProps = {
    valueKey: 'id',
    valueLabel: 'description',
    disabled: false,
    isClearable: true,
    isSearchable: true,
    onChangeExtraAction: function () {},
};

export default Select;
