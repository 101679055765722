import React from 'react';
import TableDefault from '../../../../components/table/default';

function EmployeeListTable({
    data,
    openEditEmployeeModal,
    openDeleteEmployeeModal,
    handleToggleActiveState,
}) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Naam',
                accessor: 'name',
                width: '24%',
            },
            {
                Header: 'E-mail',
                accessor: 'email',
                width: '30%',
            },
            {
                Header: 'Standaard locatie',
                accessor: 'location.description',
                width: '20%',
            },
            {
                Header: 'Uitzendbureau',
                accessor: 'employmentAgency.description',
                width: '20%',
            },
            {
                Header: 'Actief',
                accessor: 'active',
                width: '8%',
                Cell: ({ row, cell: { value } }) => {
                    return value ? 'Ja' : 'Nee';
                },
            },
        ],

        []
    );

    function handleOnClickEdit(rowData) {
        openEditEmployeeModal(rowData);
    }

    function handleOnClickDelete(rowData) {
        openDeleteEmployeeModal(rowData);
    }

    function handleOnClickToggleActive(rowData) {
        handleToggleActiveState(rowData);
    }

    return (
        <TableDefault
            columns={columns}
            data={data}
            handleOnClickEdit={handleOnClickEdit}
            handleOnClickDelete={handleOnClickDelete}
            handleOnClickToggleActive={handleOnClickToggleActive}
        />
    );
}

export default EmployeeListTable;
