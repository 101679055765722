import { Formik } from 'formik';
import React, {useState} from 'react';
import Modal from '../../../../../../components/modal';
import LocationForm from '../default-form';
import getValidationSchemaLocation from '../default-form/getValidationSchemaLocation';
import Notification from "../../../../../../components/notification";
import LocationAPI from '../../../../../../api/location/LocationAPI';

function LocationEdit({ selectedLocation, setOpen, open, updateItem, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    function handleSubmit(values, setSubmittingDone) {
        LocationAPI.update(values)
            .then((payload) => {
                updateItem(payload.data.data);

                // show notification (updated successfully!)
                setNotificationParent({ message: `Aanpassing gegevens van locatie ${payload.data.data.description} zijn opgeslagen`, variant: 'success', visible: true, error: {} });

                setOpen(false);
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Fout bij aanpassing locatie`, variant: 'error', visible: true, error: error });
            });
        setSubmittingDone();
    }

    return (
        <Formik
            initialValues={selectedLocation}
            validationSchema={getValidationSchemaLocation()}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        title={'Locatie bewerken'}
                        open={open}
                        setOpen={setOpen}
                        confirmAction={handleSubmit}
                        confirmText={'Bijwerken'}
                    >
                        {notification.visible && (
                            <Notification message={notification.message} variant={notification.variant} error={notification.error}
                                          onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
                        )}
                        <LocationForm />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default LocationEdit;
