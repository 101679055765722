import React from 'react';
import { MdDelete, MdEdit, MdPlayArrow, MdStop } from 'react-icons/md';

function Tbody({
    getTableBodyProps,
    page,
    prepareRow,
    handleOnClickEdit,
    handleOnClickDelete,
    handleOnClickToggleActive,
}) {
    return (
        <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row);

                return (
                    <tr
                        {...row.getRowProps()}
                        className="tr-default hover:bg-yellow-50"
                    >
                        {row.cells.map((cell) => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                    className="border-gray-200 border-b p-2"
                                >
                                    {cell.render('Cell')}
                                </td>
                            );
                        })}
                        <td className="border-gray-200 border-b p-2">
                            <div className="flex justify-end space-x-2 text-xl text-gray-400">
                                {handleOnClickEdit && (
                                    <MdEdit
                                        title={'Bewerken'}
                                        className="hover:text-gray-500 cursor-pointer"
                                        onClick={() =>
                                            handleOnClickEdit(row.original)
                                        }
                                    />
                                )}

                                {handleOnClickDelete && (
                                    <MdDelete
                                        title={'Verwijderen'}
                                        className="hover:text-red-700 cursor-pointer"
                                        onClick={() =>
                                            handleOnClickDelete(row.original)
                                        }
                                    />
                                )}

                                {handleOnClickToggleActive &&
                                    (row.original.active ? (
                                        <MdStop
                                            title={'Deactiveer'}
                                            className="hover:text-red-700 cursor-pointer"
                                            onClick={() =>
                                                handleOnClickToggleActive(
                                                    row.original
                                                )
                                            }
                                        />
                                    ) : (
                                        <MdPlayArrow
                                            title={'Activeer'}
                                            className="hover:text-green-700 cursor-pointer"
                                            onClick={() =>
                                                handleOnClickToggleActive(
                                                    row.original
                                                )
                                            }
                                        />
                                    ))}
                            </div>
                        </td>
                    </tr>
                );
            })}
        </tbody>
    );
}

export default Tbody;
