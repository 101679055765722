import React from 'react';
import { useField } from 'formik';
import ErrorMessage from './parts/ErrorMessage';
import Label from './parts/Label';
import InputText from './parts/InputText';

function InputTextSet({ label, required, width, ...props }) {
    const [field, meta] = useField(props);

    return (
        <div className={`${width} mb-3`}>
            <Label htmlFor={field.id || field.name} required={required}>
                {label}
            </Label>
            <InputText {...props} />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </div>
    );
}

InputTextSet.defaultProps = {
    width: 'w-full',
    required: false,
};

export default InputTextSet;
