import React, { useContext } from 'react';
import moment from 'moment';
import HourLogAPI from '../../../../api/hour-log/HourLogAPI';
import { useHistory } from 'react-router';
import WrapperDefaultEmployee from '../../../../components/wrapper-default/employee';
import EmployeeHourLogForm from '../default-form';
import { DATE_FORMAT_BACKEND } from '../../../../constants';
import { AuthContext } from '../../../../context/AuthContext';

const initialValues = {
    date: moment().format(DATE_FORMAT_BACKEND),
    locationId: 1,
    productGroupId: '',
    typeOfWorkId: '',
    typeOfWorkAmount: '',
    description: '',
    amount: '',
};

function EmployeeHourLogNew() {
    const history = useHistory();
    const { getDefaultLocationEmployee } = useContext(AuthContext);
    const defaultLocation = getDefaultLocationEmployee();

    function handleSubmit(values, setSubmittingDone) {
        HourLogAPI.create(values)
            .then((payload) => {
                history.push('/employee');
            })
            .catch((error) => {
                console.log(error);
            });
        setSubmittingDone();
    }

    return (
        <WrapperDefaultEmployee>
            <EmployeeHourLogForm
                initialValues={{
                    ...initialValues,
                    locationId: defaultLocation?.id || 1,
                }}
                handleSubmit={handleSubmit}
            />
        </WrapperDefaultEmployee>
    );
}

export default EmployeeHourLogNew;
