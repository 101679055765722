import React from 'react';

function WrapperDefaultEmployee({ children }) {
    return (
        <div className="w-full sm:w-120 p-4 bg-white rounded shadow">
            {children}
        </div>
    );
}

export default WrapperDefaultEmployee;
