import React from 'react';
import InputTextSet from '../../../../../components/form/InputTextSet';

function ManagerForm() {
    return (
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet label={'Naam'} name="name" required />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet label={'E-mail'} name="email" required />
            </div>
        </div>
    );
}

export default ManagerForm;
