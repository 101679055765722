import { Formik } from 'formik';
import React, {useState} from 'react';
import Modal from '../../../../../components/modal';
import EmployeeAPI from '../../../../../api/employee/EmployeeAPI';
import EmployeeForm from '../default-form';
import moment from 'moment';
import { DATE_FORMAT_BACKEND } from '../../../../../constants';
import getValidationSchemaEmployee from '../default-form/getValidationSchemaEmployee';
import Notification from "../../../../../components/notification";

const initialValues = {
    date: moment().format(DATE_FORMAT_BACKEND),
    name: '',
    email: '',
    hourRate: '',
    employmentAgencyId: '',
    locationId: '',
    schedule: {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
    },
};

function EmployeeNew({ addItem, setOpen, open, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    function handleSubmit(values, setSubmittingDone) {
        EmployeeAPI.create(values)
            .then((payload) => {
                const employeeData = payload.data.data;

                EmployeeAPI.createSchedule({
                    employeeId: employeeData.id,
                    ...values.schedule,
                }).then((payload) => {
                    addItem({ ...employeeData, schedule: payload.data.data });

                    // show notification (created successfully!)
                    setNotificationParent({ message: `Medewerker ${employeeData.name} is toegevoegd`, variant: 'success', visible: true, error: {} });

                    setOpen(false);
                })
                .catch((error) => {
                    // show notification (error!)
                    setNotification({ message: `Fout bij aanmaken medewerker overzicht werkuren`, variant: 'error', visible: true, error: error });
                });
            })
            .catch((error) => {
                // show notification (error!)
                setNotification({ message: `Fout bij toevoegen medewerker`, variant: 'error', visible: true, error: error });
            });
        setSubmittingDone();
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchemaEmployee()}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, handleSubmit, errors }) => {
                return (
                    <Modal
                        title={'Medewerker nieuw'}
                        open={open}
                        setOpen={setOpen}
                        confirmAction={handleSubmit}
                        confirmText={'Toevoegen'}
                        isLoading={isSubmitting}
                    >
                        {notification.visible && (
                            <Notification message={notification.message} variant={notification.variant} error={notification.error}
                            onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
                        )}
                        <EmployeeForm />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default EmployeeNew;
