import * as Yup from 'yup';

function getValidationSchemaLocation() {
    return Yup.object({
        code: Yup.string().required('Verplicht'),
        description: Yup.string().required('Verplicht'),
    });
}

export default getValidationSchemaLocation;
