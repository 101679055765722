import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Switch } from '@headlessui/react';

function InputToggle(props) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    function onChange(value) {
        setFieldValue(field.name, value);
    }

    return (
        <Switch
            checked={field.value}
            onChange={onChange}
            className={`${
                field.value ? 'bg-green-600' : 'bg-gray-300'
            } relative inline-flex items-center h-6 rounded-full w-11`}
        >
            <span
                className={`${
                    field.value ? 'translate-x-6' : 'translate-x-1'
                } inline-block w-4 h-4 transform bg-white rounded-full`}
            />
        </Switch>
    );
}

export default InputToggle;
