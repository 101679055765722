import * as Yup from 'yup';

function getValidationSchema(typeOfWorkAmountIsRequired, t) {
    let validationSchema = Yup.object().shape({
        date: Yup.string().required(t('form_general.required')),
        productGroupId: Yup.string().required(t('form_general.required')),
        typeOfWorkId: Yup.string().required(t('form_general.required')),
        amount: Yup.string()
            .typeError(t('form_general.field_not_a_number'))
            .required(t('form_general.required')),
    });

    if (typeOfWorkAmountIsRequired) {
        validationSchema = validationSchema.concat(
            Yup.object().shape({
                typeOfWorkAmount: Yup.number()
                    .typeError(t('form_general.field_not_a_number'))
                    .required(t('form_general.required')),
            })
        );
    }

    return validationSchema;
}

export default getValidationSchema;
