import React, { useEffect, useState } from 'react';
import TableDefault from '../../../../components/table/default';
import { useHistory } from 'react-router';
import { DATE_FORMAT_WEEK, DATE_FORMAT_YEAR } from '../../../../constants';
import presenterHourLogStatus from '../../../../helpers/presenterHourLogStatus';
import presenterTotalHours from '../../../../helpers/presenterTotalHours';
import ColumnCheckbox from '../../../../components/table/default/Checkbox';
import LoadingComponent from '../../../../components/loading-component';

function HourLogOverviewTable({
    data,
    filterDate,
    reviewHours,
    setReviewItems,
}) {
    const history = useHistory();
    const [employeeIds, setEmployeeIds] = useState(
        data.map((item) => ({ id: item.id, checked: false }))
    );

    const [columnsInitialized, setColumnsInitialized] = useState(false);
    const [columns, setColumns] = useState([]);

    function setRowChecked(val, id) {
        if (!id) {
            let allIds = [...employeeIds];
            allIds.forEach((id) => {
                id.checked = val;
            });
            setEmployeeIds(allIds);
            setReviewItems(allIds);

            // TODO: Find fix to mutate checkbox items with state?
            let checkBoxes = document.querySelectorAll(
                '.overview-hours-checkbox-item'
            );
            for (let i = 0; i < checkBoxes.length; i++) {
                checkBoxes[i].checked = val;
                checkBoxes[i].disabled = val;
            }

            return;
        }

        let index = employeeIds.indexOf(employeeIds.find((i) => i.id === id));

        if (index < 0) {
            return;
        }

        let ids = [...employeeIds];
        ids[index].checked = val;

        setEmployeeIds(ids);
        setReviewItems(ids);
    }

    useEffect(() => {
        setColumnsInitialized(false);

        let checkBoxColumn = {
            Header: ({ row }) => {
                return (
                    <ColumnCheckbox
                        callback={(row) => setRowChecked(row, false)}
                    />
                );
            },
            accessor: 'id',
            width: '6%',
            Cell: ({ row, cell: { value } }) => {
                return (
                    <ColumnCheckbox
                        callback={(row) => setRowChecked(row, value)}
                        className={'overview-hours-checkbox-item'}
                    />
                );
            },
        };

        let tableColumns = [];
        let defaultTableColumns = [
            {
                Header: 'Naam',
                accessor: 'name',
                width: '12%',
            },
            {
                Header: 'Uitzendbureau',
                accessor: 'employmentAgency',
                width: '12%',
            },
            {
                Header: 'Maandag',
                accessor: 'monday',
                width: '7%',
            },
            {
                Header: 'Dinsdag',
                accessor: 'tuesday',
                width: '7%',
            },
            {
                Header: 'Woensdag',
                accessor: 'wednesday',
                width: '7%',
            },
            {
                Header: 'Donderdag',
                accessor: 'thursday',
                width: '7%',
            },
            {
                Header: 'Vrijdag',
                accessor: 'friday',
                width: '7%',
            },
            {
                Header: 'Zaterdag',
                accessor: 'saturday',
                width: '7%',
            },
            {
                Header: 'Zondag',
                accessor: 'sunday',
                width: '7%',
            },
            {
                Header: 'Totaal',
                accessor: 'total',
                width: '6%',
                Cell: ({ row, cell: { value } }) => {
                    return presenterTotalHours(
                        value,
                        row.original.totalScheduleHours
                    );
                },
            },
            {
                Header: 'Werkuren',
                accessor: 'totalScheduleHours',
                width: '6%',
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: '8%',
                Cell: ({ row, cell: { value } }) => {
                    return presenterHourLogStatus(value);
                },
            },
        ];

        if (reviewHours) {
            tableColumns = [checkBoxColumn].concat(defaultTableColumns);
        } else {
            tableColumns = defaultTableColumns;
        }

        setColumns(tableColumns);
        setColumnsInitialized(true);
    }, [reviewHours]);

    function handleOnClickEdit(rowData) {
        history.push(
            `/overzicht-uren/medewerker/${rowData.id}/${filterDate.format(
                DATE_FORMAT_YEAR
            )}/${filterDate.format(DATE_FORMAT_WEEK)}`
        );
    }

    if (!columnsInitialized) {
        return <LoadingComponent />;
    }

    return (
        <TableDefault
            columns={columns}
            data={data}
            handleOnClickEdit={handleOnClickEdit}
        />
    );
}

export default HourLogOverviewTable;
