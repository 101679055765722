import React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import {
    DATE_FORMAT_DEFAULT_DISPLAY,
    DATE_FORMAT_WEEK,
} from '../../../../constants';
import { useTranslation } from 'react-i18next';

function EmployeeHourLogListToolbar({ filterDate, setFilterDate }) {
    const { t } = useTranslation();

    return (
        <div className="flex justify-between mb-4">
            <div className="text-2xl">
                <div
                    className="p-1 cursor-pointer rounded hover:bg-gray-100"
                    onClick={() =>
                        setFilterDate(filterDate.clone().subtract(1, 'week'))
                    }
                >
                    <MdChevronLeft />
                </div>
            </div>
            <div className="flex space-x-4 justify-center text-center">
                <div>
                    <strong>
                        {t('hour_log.label_week')}{' '}
                        {filterDate.format(DATE_FORMAT_WEEK)}
                    </strong>
                    <div>
                        <small>
                            {filterDate
                                .clone()
                                .startOf('week')
                                .format(DATE_FORMAT_DEFAULT_DISPLAY)}{' '}
                            -{' '}
                            {filterDate
                                .clone()
                                .endOf('week')
                                .format(DATE_FORMAT_DEFAULT_DISPLAY)}
                        </small>
                    </div>
                </div>
            </div>
            <div className="text-2xl">
                <div
                    className="p-1 cursor-pointer rounded hover:bg-gray-100"
                    onClick={() =>
                        setFilterDate(filterDate.clone().add(1, 'week'))
                    }
                >
                    <MdChevronRight />
                </div>
            </div>
        </div>
    );
}

export default EmployeeHourLogListToolbar;
