import React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import FilterDate from './FilterDate';
import {
    DATE_FORMAT_DAY_WEEK_AND_DATE,
    DATE_FORMAT_WEEK,
} from '../../../../../constants';

function HourLogOverviewToolbar({ filterDate, setFilterDate }) {
    return (
        <div className="flex justify-between mb-4">
            <div className="text-2xl">
                <div
                    className="p-1 cursor-pointer rounded hover:bg-gray-100"
                    onClick={() =>
                        setFilterDate(filterDate.clone().subtract(1, 'week'))
                    }
                >
                    <MdChevronLeft />
                </div>
            </div>
            <div className="flex space-x-4 justify-between">
                <div>
                    <FilterDate
                        filterDate={filterDate}
                        setFilterDate={setFilterDate}
                    />
                </div>
                <div>
                    <strong>Week {filterDate.format(DATE_FORMAT_WEEK)}</strong>{' '}
                    {filterDate
                        .clone()
                        .startOf('week')
                        .format(DATE_FORMAT_DAY_WEEK_AND_DATE)}{' '}
                    t/m{' '}
                    {filterDate
                        .clone()
                        .endOf('week')
                        .format(DATE_FORMAT_DAY_WEEK_AND_DATE)}
                </div>
            </div>
            <div className="text-2xl">
                <div
                    className="p-1 cursor-pointer rounded hover:bg-gray-100"
                    onClick={() =>
                        setFilterDate(filterDate.clone().add(1, 'week'))
                    }
                >
                    <MdChevronRight />
                </div>
            </div>
        </div>
    );
}

export default HourLogOverviewToolbar;
