import {
    HOURLOG_STATUS_APPROVED,
    HOURLOG_STATUS_OPEN,
    HOURLOG_STATUS_REJECTED,
} from '../constants';
import React from 'react';

function presenterHourLogStatus(status) {
    switch (status) {
        case HOURLOG_STATUS_OPEN:
            return (
                <div className="bg-gray-200 text-gray-500 flex justify-center align-content-center rounded text-sm py-1 px-2 w-28">
                    Open
                </div>
            );
        case HOURLOG_STATUS_REJECTED:
            return (
                <div className="bg-red-600 text-white flex justify-center align-content-center rounded text-sm py-1 px-2 w-28">
                    Afgekeurd
                </div>
            );
        case HOURLOG_STATUS_APPROVED:
            return (
                <div className="bg-green-600 text-white flex justify-center align-content-center rounded text-sm py-1 px-2 w-28">
                    Goedgekeurd
                </div>
            );
        default:
            return '-';
    }
}

export default presenterHourLogStatus;
