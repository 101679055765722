import React from 'react';
import { ClipLoader } from 'react-spinners';

function ButtonTertiary(props) {
    return (
        <button
            className="btn text-gray-400 text-sm py-1 rounded hover:underline"
            type={'button'}
            {...props}
        >
            {props.children}{' '}
            {props.isLoading ? <ClipLoader color={'white'} size={17} /> : null}
        </button>
    );
}

export default ButtonTertiary;
