import React from 'react';
import { useField } from 'formik';
import ErrorMessage from './parts/ErrorMessage';
import Label from './parts/Label';
import TextArea from './parts/TextArea';

function TextAreaSet({ label, required, ...props }) {
    const [field, meta] = useField(props);

    return (
        <div className="w-full sm:w-96 mb-3">
            <Label htmlFor={field.id || field.name} required={required}>
                {label}
            </Label>
            <TextArea {...props} />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </div>
    );
}

TextAreaSet.defaultProps = {
    required: false,
};

export default TextAreaSet;
