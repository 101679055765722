import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';

// Execute logout procedure and redirect to login page
function Logout() {
    const { logout } = useContext(AuthContext);

    logout();

    return <Redirect to={'/login'} />;
}

export default Logout;
