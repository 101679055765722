import React, { useState } from 'react';
import ForgotForm from './Form';
import AuthAPI from '../../../api/auth/AuthAPI';
import LogoImage from '../../../images/Logo_Darthuizer_Groep.svg';
import { useTranslation } from 'react-i18next';

function Forgot() {
    const [showSuccessMessage, toggleSuccessMessage] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    function handleSubmit(values, submittingFinished) {
        AuthAPI.forgot(values)
            .then((payload) => {
                toggleSuccessMessage(true);
                submittingFinished();
            })
            .catch((error) => {
                setError(t('general.something_went_wrong_on_server'));
                submittingFinished();
            });
    }

    return (
        <div className="bg-gray-50 w-full h-screen flex justify-center items-center bg-default-background">
            <div className="w-full sm:w-2/3 md:w-128 h-full sm:h-auto bg-white sm:rounded-lg sm:shadow-2xl p-4 sm:p-8 py-8">
                <div className="flex flex-col items-center">
                    <img src={LogoImage} alt="Logo Darthuizer Groep" />
                    <div className="w-full max-w-sm my-8">
                        <h3 className="mb-4 font-semibold text-lg">
                            {t('authentication.forgot.password_forgotten')}?
                        </h3>
                        <p className="text-gray-600 mb-4">
                            {t('authentication.forgot.description')}
                        </p>
                        <ForgotForm
                            handleSubmit={handleSubmit}
                            showSuccessMessage={showSuccessMessage}
                        />
                        <div>{error}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Forgot;
