import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

/*
 * Based on role do a redirect.
 *
 * - Employee goes from path '/' to '/employee'
 * - Manager goes from path '/' to '/manager'
 * - If user is not logged in then redirect to login page
 */
const ProtectedRouteDefaultPathRedirector = () => {
    const { isAuth, isEmployee, isManager } = useContext(AuthContext);

    if (isAuth && isEmployee()) {
        return <Redirect to="/employee" />;
    }

    if (isAuth && isManager()) {
        return <Redirect to="/manager" />;
    }

    return <Redirect to="/login" />;
};

export default ProtectedRouteDefaultPathRedirector;
