import React from 'react';
import { useField } from 'formik';
import ErrorMessage from './parts/ErrorMessage';
import Label from './parts/Label';
import Select from './parts/Select';

function SelectSet({ label, required, ...props }) {
    const [field, meta] = useField(props);

    return (
        <div className="w-full sm:w-80 mb-3">
            <Label htmlFor={field.id || field.name} required={required}>
                {label}
            </Label>
            <Select {...props} />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </div>
    );
}

SelectSet.defaultProps = {
    required: false,
};

export default SelectSet;
