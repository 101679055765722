import React, {useEffect, useState} from 'react';
import WrapperDefaultManager from '../../../../components/wrapper-default/manager';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';
import useGetEmployeeListData from '../../../../hooks/useGetEmployeeListData';
import LoadingComponent from '../../../../components/loading-component';
import EmployeeListTable from './Table';
import EmployeeNew from './new';
import EmployeeEdit from './edit';
import EmployeeDelete from './delete';
import EmployeeAPI from '../../../../api/employee/EmployeeAPI';
import Notification from "../../../../components/notification";

function EmployeeList() {
    const {
        data,
        isLoading,
        notificationFetch,
        addItem,
        updateItem,
        deleteItem,
    } = useGetEmployeeListData();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    function openEditEmployeeModal(employee) {
        setSelectedEmployee(employee);
        setShowEditModal(true);
    }

    function openDeleteEmployeeModal(employee) {
        setSelectedEmployee(employee);
        setShowDeleteModal(true);
    }

    function handleToggleActiveState(employee) {
        EmployeeAPI.toggle(employee.id)
            .then((payload) => {
                updateItem(payload.data.data);
                setNotification({ message: `Medewerker ${employee.name} is ${
                                 payload.data.data.active
                                     ? 'geactiveerd'
                                     : 'gedeactiveerd'
                             }`, variant: 'success', visible: true, error: {} });
            })
            .catch((error) => {
                // show notification (error!)
                setNotification({ message: `Fout bij (de)activeren medewerker`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <WrapperDefaultManager>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                       onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <div className="flex justify-end">
                <ButtonPrimary onClick={() => setShowNewModal(true)}>
                    Nieuw
                </ButtonPrimary>
            </div>

            <EmployeeListTable
                data={data}
                openEditEmployeeModal={openEditEmployeeModal}
                openDeleteEmployeeModal={openDeleteEmployeeModal}
                handleToggleActiveState={handleToggleActiveState}
            />

            {showNewModal && (
                <EmployeeNew
                    open={showNewModal}
                    setOpen={setShowNewModal}
                    addItem={addItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showEditModal && (
                <EmployeeEdit
                    open={showEditModal}
                    setOpen={setShowEditModal}
                    selectedEmployee={selectedEmployee}
                    updateItem={updateItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showDeleteModal && (
                <EmployeeDelete
                    open={showDeleteModal}
                    setOpen={setShowDeleteModal}
                    selectedEmployee={selectedEmployee}
                    deleteItem={deleteItem}
                    setNotificationParent={setNotification}
                />
            )}
        </WrapperDefaultManager>
    );
}

export default EmployeeList;
