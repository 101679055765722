import axiosInstance from '../default-setup/AxiosInstance';

export default {
    index: function () {
        const requestUrl = 'employees';

        return axiosInstance.get(requestUrl);
    },

    create: function (payload) {
        const requestUrl = 'employees';

        return axiosInstance.post(requestUrl, payload);
    },

    update: function (payload) {
        const requestUrl = `employees/${payload.id}`;

        return axiosInstance.put(requestUrl, payload);
    },

    delete: function (id) {
        const requestUrl = `employees/${id}`;

        return axiosInstance.delete(requestUrl);
    },

    toggle: function (id) {
        const requestUrl = `employees/${id}/toggle`;

        return axiosInstance.post(requestUrl);
    },

    createSchedule: function (payload) {
        const requestUrl = 'schedules';

        return axiosInstance.post(requestUrl, payload);
    },

    updateSchedule: function (payload) {
        const requestUrl = `schedules/${payload.id}`;

        return axiosInstance.put(requestUrl, payload);
    },
};
