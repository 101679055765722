import React, {useState, useEffect} from 'react';
import WrapperDefaultManager from '../../../../../components/wrapper-default/manager';
import ButtonPrimary from '../../../../../components/button/ButtonPrimary';
import useGetProductGroupListData from '../../../../../hooks/useGetProductGroupListData';
import LoadingComponent from '../../../../../components/loading-component';
import ProductGroupListTable from './Table';
import ProductGroupAPI from '../../../../../api/product-group/ProductGroupAPI';
import Notification from "../../../../../components/notification";
import ProductGroupNew from './new';
import ProductGroupEdit from './edit';
import ProductGroupDelete from './delete';

function ProductGroupList() {
    const {
        data,
        isLoading,
        notificationFetch,
        addItem,
        updateItem,
        deleteItem,
    } = useGetProductGroupListData();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedProductGroup, setSelectedProductGroup] = useState(null);
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    function openEditProductGroupModal(productGroup) {
        setSelectedProductGroup(productGroup);
        setShowEditModal(true);
    }

    function openDeleteProductGroupModal(productGroup) {
        setSelectedProductGroup(productGroup);
        setShowDeleteModal(true);
    }

    function handleToggleActiveState(productGroup) {
        ProductGroupAPI.toggle(productGroup.id)
            .then((payload) => {
                updateItem(payload.data.data);
                setNotification({ message: `Productgroep ${productGroup.description} is ${
                        payload.data.data.active
                            ? 'geactiveerd'
                            : 'gedeactiveerd'
                    }`, variant: 'success', visible: true, error: {} });
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Fout bij (de)activeren productgroep`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <WrapperDefaultManager>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                       onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <div className="flex justify-end">
                <ButtonPrimary onClick={() => setShowNewModal(true)}>
                    Nieuw
                </ButtonPrimary>
            </div>

            <ProductGroupListTable
                data={data}
                openEditProductGroupModal={openEditProductGroupModal}
                openDeleteProductGroupModal={openDeleteProductGroupModal}
                handleToggleActiveState={handleToggleActiveState}
            />

            {showNewModal && (
                <ProductGroupNew
                    open={showNewModal}
                    setOpen={setShowNewModal}
                    addItem={addItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showEditModal && (
                <ProductGroupEdit
                    open={showEditModal}
                    setOpen={setShowEditModal}
                    selectedProductGroup={selectedProductGroup}
                    updateItem={updateItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showDeleteModal && (
                <ProductGroupDelete
                    open={showDeleteModal}
                    setOpen={setShowDeleteModal}
                    selectedProductGroup={selectedProductGroup}
                    deleteItem={deleteItem}
                    setNotificationParent={setNotification}
                />
            )}
        </WrapperDefaultManager>
    );
}

export default ProductGroupList;
