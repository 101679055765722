function getValueOfOptionLabel(language) {
    switch (language) {
        case 'nl':
        case 'nl_NL':
            return 'description';
        case 'pl':
            return 'descriptionPl';
        case 'en':
        case 'en_EN':
        default:
            return 'descriptionEn';
    }
}

export default getValueOfOptionLabel;
