import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import ButtonLinkTertiary from '../../../components/button/ButtonTertiaryLink';
import InputPasswordSet from '../../../components/form/InputPasswordSet';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';

function ResetForm({ handleSubmit, showSuccessMessage }) {
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={{ password: '', password_confirmation: '' }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .min(
                        8,
                        t('authentication.reset.password_requirements.length')
                    )
                    .matches(
                        /([0-9])/,
                        t('authentication.reset.password_requirements.number')
                    )
                    .matches(
                        /([A-Z])/,
                        t(
                            'authentication.reset.password_requirements.uppercase'
                        )
                    )
                    .required(t('form_general.required')),
                password_confirmation: Yup.string()
                    .oneOf(
                        [Yup.ref('password'), null],
                        t('authentication.reset.form.password_must_be_equal')
                    )
                    .required(t('form_general.required')),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <div>
                            <InputPasswordSet
                                type="password"
                                label={t(
                                    'authentication.reset.form.password_label_new'
                                )}
                                name="password"
                                placeholder={t(
                                    'authentication.reset.form.password_placeholder_new'
                                )}
                                autoComplete={'new-password'}
                            />
                            <InputPasswordSet
                                type="password"
                                label={t(
                                    'authentication.reset.form.password_label_new_repeat'
                                )}
                                name="password_confirmation"
                                placeholder={t(
                                    'authentication.reset.form.password_placeholder_new'
                                )}
                                autoComplete={'new-password'}
                            />
                            <ButtonGroup>
                                <ButtonLinkTertiary to={'/login'}>
                                    {t(
                                        'authentication.reset.button.back_to_login'
                                    )}
                                </ButtonLinkTertiary>
                                <ButtonPrimary
                                    isLoading={isSubmitting}
                                    disabled={
                                        isSubmitting || showSuccessMessage
                                    }
                                    type={'submit'}
                                >
                                    {t(
                                        'authentication.reset.button.change_password'
                                    )}
                                </ButtonPrimary>
                            </ButtonGroup>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default ResetForm;
