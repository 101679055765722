import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ResetForm from './Form';
import AuthAPI from '../../../api/auth/AuthAPI';
import { AuthContext } from '../../../context/AuthContext';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import LogoImage from '../../../images/Logo_Darthuizer_Groep.svg';
import { useTranslation } from 'react-i18next';

function Reset({ location, match }) {
    const [showSuccessMessage, toggleSuccessMessage] = useState(false);
    const { login } = useContext(AuthContext);
    const { t } = useTranslation();

    const token = decodeURIComponent(match.params.token);
    const email = decodeURIComponent(match.params.email);

    const [redirectToReferrer, toggleRedirect] = useState(false);
    let { from } = location.state || { from: { pathname: '/' } };

    function handleSubmit(values, submittingFinished) {
        AuthAPI.reset({
            token,
            email,
            password: values.password,
            password_confirmation: values.password_confirmation,
        })
            .then((payload) => {
                toggleSuccessMessage(true);
                AuthAPI.login({ email, password: values.password })
                    .then((payload) => {
                        login(payload.data.token, () => {});

                        setTimeout(() => {
                            toggleRedirect(true);
                        }, 5000);
                    })
                    .catch((error) => {
                        submittingFinished();
                    });
            })
            .catch((error) => {
                submittingFinished();
            });
    }

    function redirect() {
        return <Redirect to={from} />;
    }

    return (
        <>
            {redirectToReferrer ? (
                redirect()
            ) : (
                <>
                    <div className="bg-gray-50 w-full h-screen flex justify-center items-center bg-default-background">
                        <div className="w-full sm:w-2/3 md:w-128 h-full sm:h-auto bg-white sm:rounded-lg sm:shadow-2xl p-4 sm:p-8 py-8">
                            <div className="flex flex-col items-center">
                                <img
                                    src={LogoImage}
                                    alt="Logo Darthuizer Groep"
                                />
                                <div className="w-full max-w-sm my-8">
                                    {showSuccessMessage ? (
                                        <>
                                            <h3 className="mb-4 font-semibold text-lg">
                                                {t(
                                                    'authentication.reset.password_is_set'
                                                )}
                                            </h3>
                                            <p className="text-gray-600 my-1 mb-4">
                                                {t(
                                                    'authentication.reset.login_successfull'
                                                )}
                                            </p>
                                            <ButtonPrimary
                                                onClick={toggleRedirect}
                                            >
                                                {t(
                                                    'authentication.reset.continue'
                                                )}
                                            </ButtonPrimary>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="mb-4 font-semibold text-lg">
                                                {t(
                                                    'authentication.reset.change_password'
                                                )}
                                            </h3>
                                            <p className="text-gray-600 my-1">
                                                {t(
                                                    'authentication.reset.password_requirements.description'
                                                )}
                                                :
                                            </p>
                                            <ul className="list-disc text-sm text-gray-600 mb-4 pl-4">
                                                <li>
                                                    {t(
                                                        'authentication.reset.password_requirements.length'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'authentication.reset.password_requirements.uppercase'
                                                    )}
                                                </li>
                                                <li>
                                                    {t(
                                                        'authentication.reset.password_requirements.number'
                                                    )}
                                                </li>
                                            </ul>
                                            <ResetForm
                                                handleSubmit={handleSubmit}
                                                email={email}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Reset;
