import React, {useContext, useState, useEffect} from 'react';
import WrapperDefaultManager from '../../../../components/wrapper-default/manager';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';
import useGetManagerListData from '../../../../hooks/useGetManagerListData';
import LoadingComponent from '../../../../components/loading-component';
import ManagerListTable from './Table';
import ManagerNew from './new';
import ManagerEdit from './edit';
import ManagerAPI from "../../../../api/manager/ManagerAPI";
import {AuthContext} from "../../../../context/AuthContext";
import Notification from "../../../../components/notification";

function ManagerList() {
    const {data, isLoading, notificationFetch, addItem, updateItem} = useGetManagerListData();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedManager, setSelectedManager] = useState(null);
    const { id } = useContext(AuthContext);
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);

    if (isLoading) {
        return <LoadingComponent/>;
    }

    function openEditManagerModal(manager) {
        setSelectedManager(manager);
        setShowEditModal(true);
    }

    function handleToggleActiveState(manager) {
        if (id() == manager.id) {
            // show notification (error!)
            setNotification({ message: `Je kan jezelf niet ${manager.active ? 'deactiveren' : 'activeren'}`, variant: 'error', visible: true, error: {} });
            return;
        }

        if (manager.active) {
            ManagerAPI.disable(manager.id)
                .then((payload) => {
                    updateItem(payload.data.data);
                    setNotification({ message: `Manager ${manager.name} is gedeactiveerd`, variant: 'success', visible: true, error: {} });
                })
                .catch((error) => {
                    console.log(error);
                    // show notification (error!)
                    setNotification({ message: `Er is iets misgegaan met het bijwerken van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
                });
        } else {
            ManagerAPI.enable(manager.id)
                .then((payload) => {
                    updateItem(payload.data.data);
                    setNotification({ message: `Manager ${manager.name} is geactiveerd`, variant: 'success', visible: true, error: {} });
                })
                .catch((error) => {
                    console.log(error);
                    setNotification({ message: `Er is iets misgegaan met het bijwerken van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
                });
        }
    }

    return (
        <WrapperDefaultManager>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                       onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <div className="flex justify-end">
                <ButtonPrimary onClick={() => setShowNewModal(true)}>
                    Nieuw
                </ButtonPrimary>
            </div>

            <ManagerListTable
                data={data}
                openEditManagerModal={openEditManagerModal}
                handleToggleActiveState={handleToggleActiveState}
            />

            {showNewModal && (
                <ManagerNew
                    open={showNewModal}
                    setOpen={setShowNewModal}
                    addItem={addItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showEditModal && (
                <ManagerEdit
                    open={showEditModal}
                    setOpen={setShowEditModal}
                    selectedManager={selectedManager}
                    updateItem={updateItem}
                    setNotificationParent={setNotification}
                />
            )}
        </WrapperDefaultManager>
    );
}

export default ManagerList;
