import React from 'react';
import { useField, useFormikContext } from 'formik';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { DATE_FORMAT_HOUR, DATE_FORMAT_MINUTE } from '../../../constants';

function InputTime(props) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    function onChange(value) {
        if (!value) return setFieldValue(field.name, null);

        const hours = value.format(DATE_FORMAT_HOUR);
        const minutes = (value.format(DATE_FORMAT_MINUTE) * 100) / 60;

        setFieldValue(field.name, `${hours}.${minutes}`);
    }

    function formatValue() {
        if (!field.value) return moment().hour(0).minute(0);

        const result = field?.value.toString().split('.');

        return moment()
            .hour(result[0])
            .minute((result[1] / 100) * 60);
    }

    return (
        <div>
            <TimePicker
                defaultValue={formatValue()}
                showSecond={false}
                minuteStep={15}
                onChange={onChange}
                {...props}
            />
        </div>
    );
}

export default InputTime;
