import * as React from 'react';

function Label({ htmlFor, children, required }) {
    return (
        <label
            htmlFor={htmlFor}
            required={required}
            className="block text-md font-semibold text-gray-600"
        >
            {children}:
        </label>
    );
}

Label.defaultProps = {
    required: false,
};

export default Label;
