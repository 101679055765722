import React, { useContext } from 'react';
import InputTextSet from '../../../../../../components/form/InputTextSet';
import SelectSet from '../../../../../../components/form/SelectSet';
import { SystemDataContext } from '../../../../../../context/SystemDataContext';
import InputToggleSet from '../../../../../../components/form/InputToggleSet';

function TypeOfWorkForm() {
    const { productGroups } = useContext(SystemDataContext);

    return (
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <SelectSet
                    label={'Productgroep'}
                    name={'productGroupId'}
                    options={productGroups}
                    isClearable={false}
                    isSearchable={false}
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet label={'Code'} name="code" required />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet
                    label={'Omschrijving'}
                    name="description"
                    required
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet
                    label={'Omschrijving Engels'}
                    name="descriptionEn"
                    required
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet
                    label={'Omschrijving Pools'}
                    name="descriptionPl"
                    required
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputToggleSet
                    label={'Aantal benodigd'}
                    name="specifyAmount"
                    required
                />
            </div>
        </div>
    );
}

export default TypeOfWorkForm;
