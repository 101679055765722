import moment from 'moment';
import { DATE_FORMAT_FOR_FILENAME } from '../constants';

function getFileNameExportHourLogs(name) {
    let nameString = 'medewerkers';

    if (name) {
        nameString = name.replace(/ /g, '_');
    }

    return `${moment().format(
        DATE_FORMAT_FOR_FILENAME
    )}_overzicht_alle_uren_${nameString}.csv`;
}

export default getFileNameExportHourLogs;
