import { Formik } from 'formik';
import React, {useState} from 'react';
import Modal from '../../../../../components/modal';
import EmployeeAPI from '../../../../../api/employee/EmployeeAPI';
import EmployeeForm from '../default-form';
import getValidationSchemaEmployee from '../default-form/getValidationSchemaEmployee';
import Notification from "../../../../../components/notification";

const defaultSchedule = {
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
};

function EmployeeEdit({ selectedEmployee, setOpen, open, updateItem, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    function handleSubmit(values, setSubmittingDone) {
        EmployeeAPI.update(values)
            .then((payload) => {
                const employeeData = payload.data.data;

                let updateOrNewRequest;

                if (employeeData.schedule) {
                    updateOrNewRequest = EmployeeAPI.updateSchedule({
                        id: selectedEmployee.schedule.id,
                        ...values.schedule,
                    });
                } else {
                    updateOrNewRequest = EmployeeAPI.createSchedule({
                        employeeId: employeeData.id,
                        ...values.schedule,
                    });
                }

                updateOrNewRequest.then((payload) => {
                    updateItem({
                        ...employeeData,
                        schedule: payload.data.data,
                    });

                    // show notification (updated successfully!)
                    setNotificationParent({ message: `Aanpassing gegevens van medewerker ${employeeData.name} zijn opgeslagen`, variant: 'success', visible: true, error: {} });

                    setOpen(false);
                });
            })
            .catch((error) => {
                // show notification (error!)
                setNotification({ message: `Fout bij aanpassing medewerker`, variant: 'error', visible: true, error: error });
            });
        setSubmittingDone();
    }

    return (
        <Formik
            initialValues={{
                ...selectedEmployee,
                employmentAgencyId: selectedEmployee.employmentAgency?.id,
                locationId: selectedEmployee.location?.id,
                schedule: {
                    ...defaultSchedule,
                    ...selectedEmployee.schedule,
                },
            }}
            validationSchema={getValidationSchemaEmployee()}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        title={'Medewerker bewerken'}
                        open={open}
                        setOpen={setOpen}
                        confirmAction={handleSubmit}
                        confirmText={'Bijwerken'}
                    >
                        {notification.visible && (
                            <Notification message={notification.message} variant={notification.variant} error={notification.error}
                            onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
                        )}
                        <EmployeeForm />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default EmployeeEdit;
