import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import { useField, useFormikContext } from 'formik';
import {
    DATE_FORMAT_BACKEND,
    DATE_FORMAT_DEFAULT_DISPLAY,
} from '../../../constants';

const InputDate = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    const handleDayChange = (date) => {
        const formattedDate = date
            ? moment(date).format(DATE_FORMAT_BACKEND)
            : '';

        setFieldValue(field.name, formattedDate);
    };

    const formattedDate = field.value
        ? moment(field.value).format(props.formatDate)
        : '';

    return (
        <DayPickerInput
            id={props.id || field.name}
            value={formattedDate}
            onDayChange={handleDayChange}
            formatDate={(date) =>
                date ? moment(date).format(props.formatDate) : ''
            }
            parseDate={parseDate}
            dayPickerProps={{
                showWeekNumbers: true,
                locale: props.locale,
                firstDayOfWeek: 1,
                localeUtils: MomentLocaleUtils,
            }}
            inputProps={{
                name: field.name,
                autoComplete: 'off',
                disabled: props.disabled,
            }}
            placeholder={''}
        />
    );
};

InputDate.defaultProps = {
    formatDate: DATE_FORMAT_DEFAULT_DISPLAY,
    locale: 'nl',
};

export default InputDate;
