import React, { useContext, useState } from 'react';
import { SystemDataContext } from '../../../../../../../context/SystemDataContext';
import * as Yup from 'yup';
import { ErrorMessage as ErrorMessageFormik, Formik } from 'formik';
import Select from '../../../../../../../components/form/parts/Select';
import InputText from '../../../../../../../components/form/parts/InputText';
import { IoSave } from 'react-icons/io5';
import { RiArrowGoBackFill, RiDeleteBin2Fill } from 'react-icons/ri';
import { DATE_FORMAT_BACKEND } from '../../../../../../../constants';
import ErrorMessage from '../../../../../../../components/form/parts/ErrorMessage';

function HourLogEmployeeTableRowEdit({
    initialValues,
    toggleShowEdit,
    toggleShowDelete,
    updateOrCreate,
    date,
    employeeId,
}) {
    const [
        typeOfWorkAmountIsRequired,
        setTypeOfWorkAmountIsRequired,
    ] = useState(initialValues.typeOfWork?.specifyAmount || false);
    const { locations, productGroups, typeOfWorks } = useContext(
        SystemDataContext
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                productGroupId: Yup.string().required('Verplicht'),
                typeOfWorkId: Yup.string().required('Verplicht'),
                locationId: Yup.string().required('Verplicht'),
                amount: Yup.number()
                    .typeError('Ongeldig nummer')
                    .moreThan(-1, 'Geen negative nummers')
                    .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                    .required('Verplicht'),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                if (!values.typeOfWorkAmount) {
                    delete values.typeOfWorkAmount;
                }

                updateOrCreate(
                    {
                        ...values,
                        date: date.format(DATE_FORMAT_BACKEND),
                        employeeId: employeeId,
                    },
                    toggleShowEdit
                );
            }}
        >
            {({
                values,
                handleSubmit,
                validateForm,
                setFieldTouched,
                setFieldValue,
            }) => {
                function manualSetRequiredFieldsOnTouched() {
                    const fields = [
                        'productGroupId',
                        'typeOfWorkId',
                        'locationId',
                        'amount',
                        'description',
                    ];

                    fields.map((item) => setFieldTouched(item, true));
                }

                const filteredTypeOfWorks = typeOfWorks.filter(
                    (item) =>
                        item.productGroup?.id === values.productGroupId &&
                        item.active
                );

                return (
                    <tr className="tr-default hover:bg-yellow-50 border-gray-200 border-b align-top">
                        <td className="p-2">
                            <Select
                                name={'productGroupId'}
                                options={productGroups.filter(
                                    (item) => item.active
                                )}
                                isClearable={false}
                                onChangeExtraAction={() => {
                                    setFieldValue('typeOfWorkId', '');
                                    setTypeOfWorkAmountIsRequired(false);
                                }}
                            />
                            <ErrorMessageFormik
                                name="productGroupId"
                                render={(msg) => (
                                    <ErrorMessage>{msg}</ErrorMessage>
                                )}
                            />
                        </td>
                        <td className="p-2">
                            <Select
                                name={'typeOfWorkId'}
                                options={filteredTypeOfWorks}
                                isClearable={false}
                                onChangeExtraAction={(option) =>
                                    setTypeOfWorkAmountIsRequired(
                                        option.specifyAmount
                                    )
                                }
                            />
                            <ErrorMessageFormik
                                name="typeOfWorkId"
                                render={(msg) => (
                                    <ErrorMessage>{msg}</ErrorMessage>
                                )}
                            />
                        </td>
                        <td className="p-2">
                            <Select
                                name={'locationId'}
                                options={locations}
                                isClearable={false}
                            />
                            <ErrorMessageFormik
                                name="locationId"
                                render={(msg) => (
                                    <ErrorMessage>{msg}</ErrorMessage>
                                )}
                            />
                        </td>
                        <td className="p-2">
                            <InputText name="description" />
                            <ErrorMessageFormik
                                name="description"
                                render={(msg) => (
                                    <ErrorMessage>{msg}</ErrorMessage>
                                )}
                            />
                        </td>
                        <td className="p-2">{values.period}</td>
                        <td className="p-2">
                            <InputText name="amount" />
                            <ErrorMessageFormik
                                name="amount"
                                render={(msg) => (
                                    <ErrorMessage>{msg}</ErrorMessage>
                                )}
                            />
                        </td>

                        <td className="p-2">
                            {typeOfWorkAmountIsRequired ? (
                                <InputText name="typeOfWorkAmount" />
                            ) : (
                                '-'
                            )}
                        </td>
                        <td className="p-2">
                            <div className="flex justify-end space-x-2 text-lg text-gray-500">
                                <IoSave
                                    title={'Opslaan'}
                                    className="hover:text-gray-500 cursor-pointer"
                                    onClick={() => {
                                        manualSetRequiredFieldsOnTouched();
                                        validateForm().then(handleSubmit);
                                    }}
                                />
                                <RiDeleteBin2Fill
                                    title={'Verwijderen'}
                                    className="hover:text-gray-500 cursor-pointer"
                                    onClick={toggleShowDelete}
                                />
                                <RiArrowGoBackFill
                                    title={'Annuleren'}
                                    className="hover:text-gray-500 cursor-pointer"
                                    onClick={toggleShowEdit}
                                />
                            </div>
                        </td>
                    </tr>
                );
            }}
        </Formik>
    );
}

export default HourLogEmployeeTableRowEdit;
