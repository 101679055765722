import React from 'react';
import TableDefault from '../../../../components/table/default';

function ManagerListTable({ data, openEditManagerModal, handleToggleActiveState }) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Naam',
                accessor: 'name',
                width: '42%',
            },
            {
                Header: 'E-mail',
                accessor: 'email',
                width: '50%',
            },
            {
                Header: 'Actief',
                accessor: 'active',
                width: '8%',
                Cell: ({ row, cell: { value } }) => {
                    return value ? 'Ja' : 'Nee';
                },
            },
        ],

        []
    );

    function handleOnClickEdit(rowData) {
        openEditManagerModal(rowData);
    }

    function handleOnClickToggleActive(rowData) {
        handleToggleActiveState(rowData);
    }

    return (
        <TableDefault
            columns={columns}
            data={data}
            handleOnClickEdit={handleOnClickEdit}
            handleOnClickToggleActive={handleOnClickToggleActive}
        />
    );
}

export default ManagerListTable;
