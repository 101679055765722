import axiosInstance from '../default-setup/AxiosInstance';

export default {
    index: function ({ week, year }) {
        const requestUrl = `hour-logs/${week}/${year}`;

        return axiosInstance.get(requestUrl);
    },

    create: function (payload) {
        const requestUrl = `hour-logs`;

        return axiosInstance.post(requestUrl, payload);
    },

    edit: function (id) {
        const requestUrl = `hour-logs/${id}`;

        return axiosInstance.get(requestUrl);
    },

    update: function (payload) {
        const requestUrl = `hour-logs/${payload.id}`;

        return axiosInstance.put(requestUrl, payload);
    },

    delete: function (id) {
        const requestUrl = `hour-logs/${id}`;

        return axiosInstance.delete(requestUrl);
    },

    employeesWeekOverview: function ({ week, year }) {
        const requestUrl = `hour-logs/employees/all/overview/${week}/${year}`;

        return axiosInstance.get(requestUrl);
    },

    employeeWeekOverview: function ({ employeeId, week, year }) {
        const requestUrl = `hour-logs/employees/${employeeId}/overview/${week}/${year}`;

        return axiosInstance.get(requestUrl);
    },

    /*
     * @param {Object[int]} hourLogs[] - only ids
     */
    approve: function (payload) {
        const requestUrl = `hour-logs/approve`;

        return axiosInstance.put(requestUrl, payload);
    },
    approveEmployeesDateRange: function (employeeIds, date) {
        const requestUrl = `hour-logs/approve-employees-date-range`;

        return axiosInstance.put(requestUrl, {
            employees: employeeIds,
            date: date,
        });
    },

    /*
     * @param {Object[int]} hourLogs[] - only ids
     */
    reject: function (payload) {
        const requestUrl = `hour-logs/reject`;

        return axiosInstance.put(requestUrl, payload);
    },
    rejectEmployeesDateRange: function (employeeIds, date) {
        const requestUrl = `hour-logs/reject-employees-date-range`;

        return axiosInstance.put(requestUrl, {
            employees: employeeIds,
            date: date,
        });
    },

    exportToCsv: function (year, week) {
        const requestUrl = `hour-logs/export/${week}/${year}`;

        return axiosInstance.get(requestUrl, {
            responseType: 'blob',
        });
    },

    exportToCsvPerEmployee: function (employeeId, year, week) {
        const requestUrl = `hour-logs/export-employee/${employeeId}/${week}/${year}`;

        return axiosInstance.get(requestUrl, {
            responseType: 'blob',
        });
    },
};
