import React from 'react';

function presenterTotalHours(totalHours, totalScheduleHours) {
    if (totalScheduleHours === 0) {
        return totalHours;
    }

    if (totalHours >= totalScheduleHours) {
        return (
            <span className="text-green-600 font-semibold">{totalHours}</span>
        );
    }

    return <span className="text-red-600 font-semibold">{totalHours}</span>;
}

export default presenterTotalHours;
