import React, { useContext, useEffect, useRef, useState } from 'react';
import LogoImage from '../../images/Logo_Darthuizer_Groep.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import employeeMenuItems from './employeeMenuItems';
import managerMenuItems from './managerMenuItems';
import ToggleLanguage from './ToggleLanguage';
import DropdownMenu from './dropdown-menu';
import DropdownMenuMobile from './dropdown-menu-mobile';
import { SystemDataContext } from '../../context/SystemDataContext';

function Header({ title }) {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { isEmployee, isManager } = useContext(AuthContext);
    const { versionNumber } = useContext(SystemDataContext);
    const { t } = useTranslation();

    function toggleMenu() {
        setMenuIsOpen(!menuIsOpen);
    }

    function useOutsideAlerter(ref) {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setMenuIsOpen(false);
            }
        }

        useEffect(() => {
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        });
    }

    const headerRef = useRef(null);
    useOutsideAlerter(headerRef);

    return (
        <header
            className="w-screen h-32 max-h-32 fixed z-40 bg-white mx-0 px-0 shadow-md"
            ref={headerRef}
        >
            <div className="container mx-auto flex justify-between p-3">
                <div className="flex justify-start items-center lg:flex-1 space-x-2 sm:space-x-4">
                    <img src={LogoImage} alt="Logo Darthuizer Groep" />

                    <div className="flex flex-col justify-center sm:justify-end lg:flex-2">
                        <div className="font-semibold text-lg sm:text-2xl">
                            Uren registratie{' '}
                            <small className="sm:text-xs">
                                v{versionNumber}
                            </small>
                        </div>
                        <div className="text-xs sm:text-sm font-light text-primary-400">
                            DARTHUIZER PRODUCTIE B.V.
                        </div>
                    </div>
                </div>

                <nav className="bg-white hidden lg:block lg:flex-1">
                    <div className="flex space-x-2 justify-end items-center h-full">
                        {isEmployee() &&
                            employeeMenuItems.map((item, index) =>
                                item.dropdownMenu ? (
                                    <DropdownMenu
                                        key={index}
                                        label={item.label}
                                        subItems={item.subItems}
                                    />
                                ) : (
                                    <Link to={item.link} key={index}>
                                        <span className="p-2 border-b-2 border-white hover:border-primary-600">
                                            {t(item.label)}
                                        </span>
                                    </Link>
                                )
                            )}

                        {isEmployee() && <ToggleLanguage />}

                        {isManager() &&
                            managerMenuItems.map((item, index) =>
                                item.dropdownMenu ? (
                                    <DropdownMenu
                                        key={index}
                                        label={item.label}
                                        subItems={item.subItems}
                                    />
                                ) : (
                                    <Link to={item.link} key={index}>
                                        <span className="p-2 border-b-2 border-white hover:border-primary-600">
                                            {t(item.label)}
                                        </span>
                                    </Link>
                                )
                            )}
                    </div>
                </nav>

                <div className="flex justify-center items-center lg:hidden">
                    {/* Language switcher button*/}
                    {isEmployee() && <ToggleLanguage />}

                    {/*Mobile menu button*/}
                    <button
                        type="button"
                        className="inline-flex items-center justify-center h-12 w-12 p-2 rounded-md text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={toggleMenu}
                    >
                        {/*
                                  Icon when menu is closed.
                                  Heroicon name: outline/menu
                                  Menu open: "hidden", Menu closed: "block"
                                */}
                        <svg
                            className="block h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                        {/*
                                  Icon when menu is open.
                                  Heroicon name: outline/x
                                  Menu open: "block", Menu closed: "hidden"
                                */}
                        <svg
                            className="hidden h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="flex justify-center py-2 bg-primary-400 text-white uppercase font-extralight">
                {title}
            </div>

            {/*Mobile menu, show/hide based on menu state. */}
            <nav
                className={`${menuIsOpen ? 'block' : 'hidden'}`}
                id="mobile-menu"
            >
                <div className="bg-white px-2 pt-2 pb-3 space-y-1 shadow-lg">
                    {isEmployee() &&
                        employeeMenuItems.map((item, index) =>
                            item.dropdownMenu ? (
                                <DropdownMenuMobile
                                    key={index}
                                    label={item.label}
                                    subItems={item.subItems}
                                />
                            ) : (
                                <Link
                                    to={item.link}
                                    key={index}
                                    className="text-gray-500 hover:bg-gray-200 hover:text-gray-600 block
                                px-3 py-2 rounded-md text-base font-display"
                                    aria-current="page"
                                    onClick={toggleMenu}
                                >
                                    {t(item.label)}
                                </Link>
                            )
                        )}

                    {isManager() &&
                        managerMenuItems.map((item, index) =>
                            item.dropdownMenu ? (
                                <DropdownMenuMobile
                                    key={index}
                                    label={item.label}
                                    subItems={item.subItems}
                                    toggleMenu={toggleMenu}
                                />
                            ) : (
                                <Link
                                    to={item.link}
                                    key={index}
                                    className="text-gray-500 hover:bg-gray-200 hover:text-gray-600 block
                                px-3 py-2 rounded-md text-base font-display"
                                    aria-current="page"
                                    onClick={toggleMenu}
                                >
                                    {item.label}
                                </Link>
                            )
                        )}
                </div>
            </nav>
        </header>
    );
}

export default Header;
