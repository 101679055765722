import React from 'react';
import { useField } from 'formik';

function TextArea({ autoComplete, id, rows, ...props }) {
    const [field] = useField(props);

    return (
        <textarea
            {...field}
            {...props}
            id={id || field.name}
            rows={rows}
            className="px-2 py-1 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-sm border border-gray-300 outline-none focus:outline-none focus:ring w-full"
            autoComplete={autoComplete}
        />
    );
}

TextArea.defaultProps = {
    autoComplete: 'off',
    rows: 3,
};

export default TextArea;
