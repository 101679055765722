import { Formik } from 'formik';
import React, {useState} from 'react';
import Modal from '../../../../../../components/modal';
import TypeOfWorkAPI from '../../../../../../api/type-of-work/TypeOfWorkAPI';
import TypeOfWorkForm from '../default-form';
import getValidationSchemaTypeOfWork from '../default-form/getValidationSchemaTypeOfWork';
import Notification from "../../../../../../components/notification";

function TypeOfWorkEdit({ selectedTypeOfWork, setOpen, open, updateItem, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });
    function handleSubmit(values, setSubmittingDone) {
        TypeOfWorkAPI.update(values)
            .then((payload) => {
                updateItem(payload.data.data);

                // show notification (updated successfully!)
                setNotificationParent({ message: `Aanpassing gegevens van werksoort ${payload.data.data.description} zijn opgeslagen`, variant: 'success', visible: true, error: {} });

                setOpen(false);
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Fout bij aanpassing werksoort`, variant: 'error', visible: true, error: error });
            });
        setSubmittingDone();
    }

    return (
        <Formik
            initialValues={{
                ...selectedTypeOfWork,
                productGroupId: selectedTypeOfWork.productGroup.id,
            }}
            validationSchema={getValidationSchemaTypeOfWork()}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, handleSubmit }) => {
                return (
                    <Modal
                        title={'Werksoort bewerken'}
                        open={open}
                        setOpen={setOpen}
                        confirmAction={handleSubmit}
                        confirmText={'Bijwerken'}
                    >
                        {notification.visible && (
                            <Notification message={notification.message} variant={notification.variant} error={notification.error}
                                          onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
                        )}
                        <TypeOfWorkForm />
                    </Modal>
                );
            }}
        </Formik>
    );
}

export default TypeOfWorkEdit;
