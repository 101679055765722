import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonTertiary from '../button/ButtonTertiary';
import ButtonPrimary from '../button/ButtonPrimary';
import LoadingComponent from '../loading-component';

function Modal({
    open,
    setOpen,
    confirmAction,
    confirmText,
    title,
    children,
    isLoading,
    variant,
}) {
    const cancelButtonRef = useRef();

    let variantStyle = '';

    switch (variant) {
        case 'danger':
            variantStyle = 'bg-red-600';
            break;
        case 'primary':
        default:
            variantStyle = 'bg-primary-400';
            break;
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-40 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full z-50">
                            <Dialog.Title
                                as="h3"
                                className={`text-lg text-white p-2 rounded-tl-lg rounded-tr-lg ${variantStyle}`}
                            >
                                {title}
                            </Dialog.Title>
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                {isLoading ? (
                                    <LoadingComponent
                                        customClasses={'content-center '}
                                    />
                                ) : (
                                    children
                                )}
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-end space-x-4">
                                <ButtonTertiary onClick={() => setOpen(false)}>
                                    Annuleren
                                </ButtonTertiary>
                                <ButtonPrimary
                                    onClick={confirmAction}
                                    variant={variant}
                                >
                                    {confirmText}
                                </ButtonPrimary>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

Modal.defaultProps = {
    variant: 'primary',
};

export default Modal;
