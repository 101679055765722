import React from 'react';
import { useField } from 'formik';

function InputText({ autoComplete, id, ...props }) {
    const [field] = useField(props);

    return (
        <input
            {...field}
            {...props}
            id={id || field.name}
            className="px-2 py-1 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-sm border border-gray-300 outline-none focus:outline-none focus:ring w-full"
            autoComplete={autoComplete}
        />
    );
}

InputText.defaultProps = {
    autoComplete: 'off',
};

export default InputText;
