import React, { useState, useEffect } from 'react';
import WrapperDefaultManager from '../../../../../components/wrapper-default/manager';
import ButtonPrimary from '../../../../../components/button/ButtonPrimary';
import useGetTypeOfWorkListData from '../../../../../hooks/useGetTypeOfWorkListData';
import LoadingComponent from '../../../../../components/loading-component';
import TypeOfWorkListTable from './Table';
import TypeOfWorkAPI from '../../../../../api/type-of-work/TypeOfWorkAPI';
import Notification from "../../../../../components/notification";
import TypeOfWorkNew from './new';
import TypeOfWorkEdit from './edit';
import TypeOfWorkDelete from './delete';

function TypeOfWorkList() {
    const {
        data,
        isLoading,
        notificationFetch,
        addItem,
        updateItem,
        deleteItem,
    } = useGetTypeOfWorkListData();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTypeOfWork, setSelectedTypeOfWork] = useState(null);
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    function openEditTypeOfWorkModal(typeOfWork) {
        setSelectedTypeOfWork(typeOfWork);
        setShowEditModal(true);
    }

    function openDeleteTypeOfWorkModal(typeOfWork) {
        setSelectedTypeOfWork(typeOfWork);
        setShowDeleteModal(true);
    }

    function handleToggleActiveState(typeOfWork) {
        TypeOfWorkAPI.toggle(typeOfWork.id)
            .then((payload) => {
                updateItem(payload.data.data);
                setNotification({ message: `Werksoort ${typeOfWork.description} is ${
                        payload.data.data.active
                            ? 'geactiveerd'
                            : 'gedeactiveerd'
                    }`, variant: 'success', visible: true, error: {} });
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Fout bij (de)activeren werksoort`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <WrapperDefaultManager>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                       onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <div className="flex justify-end">
                <ButtonPrimary onClick={() => setShowNewModal(true)}>
                    Nieuw
                </ButtonPrimary>
            </div>

            <TypeOfWorkListTable
                data={data}
                openEditTypeOfWorkModal={openEditTypeOfWorkModal}
                openDeleteTypeOfWorkModal={openDeleteTypeOfWorkModal}
                handleToggleActiveState={handleToggleActiveState}
            />

            {showNewModal && (
                <TypeOfWorkNew
                    open={showNewModal}
                    setOpen={setShowNewModal}
                    addItem={addItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showEditModal && (
                <TypeOfWorkEdit
                    open={showEditModal}
                    setOpen={setShowEditModal}
                    selectedTypeOfWork={selectedTypeOfWork}
                    updateItem={updateItem}
                    setNotificationParent={setNotification}
                />
            )}

            {showDeleteModal && (
                <TypeOfWorkDelete
                    open={showDeleteModal}
                    setOpen={setShowDeleteModal}
                    selectedTypeOfWork={selectedTypeOfWork}
                    deleteItem={deleteItem}
                    setNotificationParent={setNotification}
                />
            )}
        </WrapperDefaultManager>
    );
}

export default TypeOfWorkList;
