import { useEffect, useReducer } from 'react';
import HourLogAPI from '../api/hour-log/HourLogAPI';
import { DATE_FORMAT_WEEK, DATE_FORMAT_YEAR } from '../constants';

const INITIAL_STATE = {
    data: [],
    isLoading: false,
    notificationFetch: {message: '', variant: '', visible: false, error: {}}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'RESULT':
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case 'IS_LOADING':
            return { ...state, isLoading: action.payload };
        case 'NOTIFICATION_FETCH':
            return { ...state, notificationFetch: action.payload };
        default:
            return state;
    }
};

function useGetHourLogEmployeeListData(filterDate) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        setIsLoading(true);
        HourLogAPI.index({
            week: filterDate.format(DATE_FORMAT_WEEK),
            year: filterDate.format(DATE_FORMAT_YEAR),
        })
            .then((payload) => {
                setResult(payload.data.data);
            })
            .catch((error) => {
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het ophalen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
                setIsLoading(false);
            });
    }, [filterDate]);

    function setIsLoading(payload) {
        dispatch({
            type: 'IS_LOADING',
            payload: payload,
        });
    }

    function setResult(payload) {
        dispatch({
            type: 'RESULT',
            payload: payload,
        });
    }

    function setNotificationFetch(payload) {
        dispatch({
            type: 'NOTIFICATION_FETCH',
            payload: payload,
        });
    }

    return {
        data: state.data,
        isLoading: state.isLoading,
        notificationFetch: state.notificationFetch,
    };
}

export default useGetHourLogEmployeeListData;
