import React, { useState } from 'react';

function ColumnCheckbox({ callback, className }) {
    const [checked, setChecked] = useState(false);

    function onChange(e) {
        setChecked(e.target.checked);
        callback(e.target.checked);
    }

    return (
        <input
            type={'checkbox'}
            defaultChecked={checked}
            onChange={onChange}
            className={className}
        />
    );
}

export default ColumnCheckbox;
