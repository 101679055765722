import React from 'react';
import {
    MdChevronLeft,
    MdChevronRight,
    MdFirstPage,
    MdLastPage,
} from 'react-icons/md';

function Pagination({
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    pageSize,
    pageOptions,
}) {
    return (
        <div className="flex justify-between py-3">
            <select
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                }}
            >
                {[25, 50, 75, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        Toon {pageSize}
                    </option>
                ))}
            </select>
            <div className="text-xl">
                <button
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                    className="text-gray-500 disabled:opacity-50"
                >
                    <MdFirstPage />
                </button>{' '}
                <button
                    onClick={previousPage}
                    disabled={!canPreviousPage}
                    className="text-gray-500 disabled:opacity-50"
                >
                    <MdChevronLeft />
                </button>{' '}
                <button
                    onClick={nextPage}
                    disabled={!canNextPage}
                    className="text-gray-500 disabled:opacity-50"
                >
                    <MdChevronRight />
                </button>{' '}
                <button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    className="text-gray-500 disabled:opacity-50"
                >
                    <MdLastPage />
                </button>
            </div>
            <div className="text-base">
                Pagina{' '}
                <strong>
                    {pageIndex + 1} van {pageOptions.length}
                </strong>{' '}
            </div>
        </div>
    );
}

export default Pagination;
