import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';

function DropdownMenuMobile({ label, subItems, toggleMenu }) {
    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button
                        className="flex justify-between w-full text-gray-500 hover:bg-gray-200 hover:text-gray-600 block
                                px-3 py-2 rounded-md text-base font-display"
                    >
                        {label}
                        {open ? <FaChevronDown /> : <FaChevronUp />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="m-1 p-1 bg-gray-200 rounded">
                        {subItems.map((item, index) => (
                            <Link to={item.link} onClick={toggleMenu}>
                                <div
                                    key={index}
                                    className="m-1 ml-4 p-1 rounded text-gray-500 hover:text-gray-600"
                                >
                                    {item.label}
                                </div>
                            </Link>
                        ))}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export default DropdownMenuMobile;
