import React from 'react';
import { BeatLoader } from 'react-spinners';

function LoadingComponent({ customClasses }) {
    return (
        <div className={`flex justify-center ${customClasses}`}>
            <BeatLoader color={'#cbba6e'} size={12} />
        </div>
    );
}

LoadingComponent.defaultProps = {
    customClasses: 'mt-32',
};

export default LoadingComponent;
