import * as Yup from 'yup';

function getValidationSchemaTypeOfWork() {
    return Yup.object({
        productGroupId: Yup.string().required('Verplicht'),
        description: Yup.string().required('Verplicht'),
        descriptionEn: Yup.string().required('Verplicht'),
        descriptionPl: Yup.string().required('Verplicht'),
        specifyAmount: Yup.bool().required('Verplicht'),
    });
}

export default getValidationSchemaTypeOfWork;
