import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';

import moment from 'moment';
import HourLogEmployeeTable from './table';
import {
    DATE_FORMAT_BACKEND,
    DATE_FORMAT_DAY_WEEK_AND_DATE,
} from '../../../../../constants';

function HourLogEmployeeDayAccordion({
    data,
    createEmptyHourLog,
    updateOrCreate,
    deleteItem,
    employeeId,
    setNotificationParent,
}) {
    let [isOpen, setIsOpen] = useState(data.hourLogs.length);
    const date = moment(data.date);
    const totalHours = data.hourLogs?.reduce(
        (total, { amount }) => total + Number(amount),
        0
    );

    return (
        <div className="w-full pt-4">
            <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                <div
                    className="flex justify-between content-center w-full px-4 py-2 text-sm font-medium text-left text-gray-600 bg-gray-200 hover:bg-gray-300 rounded cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="capitalize">
                        {date.format(DATE_FORMAT_DAY_WEEK_AND_DATE)}
                    </span>
                    <div className="flex space-x-8">
                        <span>Totaal uren {totalHours}</span>
                        <div
                            className="px-2 py-1 hover:bg-gray-400 rounded"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(true);
                                createEmptyHourLog(
                                    date.format(DATE_FORMAT_BACKEND)
                                );
                            }}
                        >
                            <FaPlus />
                        </div>
                        <div className="text-lg py-1">
                            {isOpen ? <FaChevronDown /> : <FaChevronUp />}
                        </div>
                    </div>
                </div>
                {isOpen ? (
                    <div className="pb-4 text-sm" static>
                        <HourLogEmployeeTable
                            hourLogs={data.hourLogs}
                            updateOrCreate={updateOrCreate}
                            deleteItem={deleteItem}
                            date={date}
                            employeeId={employeeId}
                            setNotificationParent={setNotificationParent}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default HourLogEmployeeDayAccordion;
