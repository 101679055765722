import React, { useState } from 'react';
import { decodeToken } from 'react-jwt';

const AuthContext = React.createContext();

const LOCAL_STORAGE_TOKEN_PATH = '__darthuizer-uren-token__';

const AuthProvider = function (props) {
    const [isAuth, setAuth] = useState(checkIfAuth());

    function login(token, callback) {
        // Remove stored filters
        for (const item in localStorage) {
            if (
                item.includes('__darthuizer-uren-filters_') ||
                item.includes('__darthuizer-uren-sort_by_')
            ) {
                localStorage.removeItem(item);
            }
        }

        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_PATH, token);

        setAuth(true);
        callback();
    }

    function logout() {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_PATH);

        setAuth(false);
    }

    function checkIfAuth() {
        const token = getToken();

        if (!token || token === 'undefined') {
            // Redirect to login
            return false;
        }

        return true;
    }

    function getToken() {
        return window.localStorage.getItem(LOCAL_STORAGE_TOKEN_PATH);
    }

    function isEmployee() {
        if (!checkIfAuth()) return null;

        const { role } = decodeToken(getToken());

        return role === 'employee';
    }

    function isManager() {
        if (!checkIfAuth()) return null;

        const { role } = decodeToken(getToken());

        return role === 'manager';
    }

    function getId() {
        if (!checkIfAuth()) return null;

        const { sub } = decodeToken(getToken());

        return sub;
    }

    function getDefaultLocationEmployee() {
        const { defaultLocation } = decodeToken(getToken());

        return defaultLocation;
    }

    return (
        <AuthContext.Provider
            value={{
                isAuth,
                isEmployee,
                isManager,
                id: getId,
                login: login,
                logout: logout,
                getDefaultLocationEmployee,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
