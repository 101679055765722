const employeeMenuItems = [
    {
        link: '/',
        label: 'Dashboard',
    },
    {
        link: '/overzicht-uren',
        label: 'Overzicht uren',
    },
    {
        label: 'Instellingen',
        dropdownMenu: true,
        subItems: [
            {
                link: '/medewerkers',
                label: 'Medewerkers',
            },
            {
                link: '/managers',
                label: 'Managers',
            },
            {
                link: '/instellingen/locaties',
                label: 'Locaties',
            },
            {
                link: '/instellingen/productgroepen',
                label: 'Productgroepen',
            },
            {
                link: '/instellingen/werksoorten',
                label: 'Werksoorten',
            },
        ],
    },
    {
        link: '/logout',
        label: 'Uitloggen',
    },
];

export default employeeMenuItems;
