import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import Thead from './thead';
import Tbody from './tbody';
import Pagination from './Pagination';

function TableDefault({
    data,
    columns,
    initialState,
    handleOnClickEdit,
    handleOnClickDelete,
    handleOnClickToggleActive,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = useTable({ columns, data, initialState : { pageSize: 25 }}, useSortBy, usePagination);

    return (
        <>
            <table
                {...getTableProps()}
                className="w-full my-1 text-sm table-fixed"
            >
                <Thead headerGroups={headerGroups} />
                <Tbody
                    getTableBodyProps={getTableBodyProps}
                    page={page}
                    prepareRow={prepareRow}
                    handleOnClickEdit={handleOnClickEdit}
                    handleOnClickDelete={handleOnClickDelete}
                    handleOnClickToggleActive={handleOnClickToggleActive}
                />
            </table>

            <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
            />
        </>
    );
}

export default TableDefault;
