import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import ButtonLinkTertiary from '../../../components/button/ButtonTertiaryLink';
import ButtonGroup from '../../../components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import InputTextSet from '../../../components/form/InputTextSet';

function ForgotForm({ handleSubmit, showSuccessMessage }) {
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email(t('form_general.invalid_email_address'))
                    .required(t('form_general.required')),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <div>
                            <InputTextSet
                                label={t('authentication.forgot.email_label')}
                                name="email"
                                placeholder={t(
                                    'authentication.forgot.email_placeholder'
                                )}
                            />
                            <ButtonGroup>
                                <ButtonLinkTertiary to={'/login'}>
                                    {t(
                                        'authentication.forgot.button.back_to_login'
                                    )}
                                </ButtonLinkTertiary>
                                <ButtonPrimary
                                    isLoading={isSubmitting}
                                    disabled={
                                        isSubmitting || showSuccessMessage
                                    }
                                    type={'submit'}
                                >
                                    {showSuccessMessage
                                        ? t(
                                              'authentication.forgot.button.email_is_sent'
                                          )
                                        : t(
                                              'authentication.forgot.button.send_password_reset_link'
                                          )}
                                </ButtonPrimary>
                            </ButtonGroup>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default ForgotForm;
