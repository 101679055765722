import React, { useContext } from 'react';
import InputTextSet from '../../../../../components/form/InputTextSet';
import SelectSet from '../../../../../components/form/SelectSet';
import { SystemDataContext } from '../../../../../context/SystemDataContext';

const weekDays = [
    { id: 'monday', label: 'Ma' },
    { id: 'tuesday', label: 'Di' },
    { id: 'wednesday', label: 'Wo' },
    { id: 'thursday', label: 'Do' },
    { id: 'friday', label: 'Vr' },
    { id: 'saturday', label: 'Za' },
    { id: 'sunday', label: 'Zo' },
];

function EmployeeForm() {
    const { locations, employmentAgencies } = useContext(SystemDataContext);

    return (
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet label={'Naam'} name="name" required />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet label={'E-mail'} name="email" required />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <SelectSet
                    label={'Standaard locatie'}
                    name={'locationId'}
                    options={locations}
                    required
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <SelectSet
                    label={'Uitzendbureau'}
                    name={'employmentAgencyId'}
                    options={employmentAgencies}
                    required
                />
            </div>

            <div className="px-2 w-full md:w-1/2 lg:w-1/2">
                <InputTextSet
                    label={'Uurloon'}
                    name="hourRate"
                    width="w-24"
                    type="number"
                    required
                />
            </div>

            <div className="px-2 w-full mt-2">
                <div>
                    <h4 className="text-gray-500 text-sm font-light uppercase">
                        Standaard werkuren
                    </h4>
                </div>
                <div className="flex space-x-2">
                    {weekDays.map((item) => (
                        <InputTextSet
                            key={item.id}
                            label={item.label}
                            name={'schedule.' + item.id}
                            width="w-16"
                            type="number"
                            required
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default EmployeeForm;
