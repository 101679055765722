import { useEffect, useReducer } from 'react';
import HourLogAPI from '../api/hour-log/HourLogAPI';

const INITIAL_STATE = {
    data: {},
    isLoading: true,
    notificationFetch: {message: '', variant: '', visible: false, error: {}}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'RESULT':
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case 'IS_LOADING':
            return { ...state, isLoading: action.payload };
        case 'NOTIFICATION_FETCH':
            return { ...state, notificationFetch: action.payload };
        default:
            return state;
    }
};

function useGetHourLogEmployeeEditData(id) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        setIsLoading(true);
        HourLogAPI.edit(id)
            .then((payload) => {
                setResult(payload.data.data);
            })
            .catch((error) => {
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het ophalen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
                setIsLoading(false);
            });
    }, [id]);

    function setIsLoading(payload) {
        dispatch({
            type: 'IS_LOADING',
            payload: payload,
        });
    }

    function setResult(payload) {
        dispatch({
            type: 'RESULT',
            payload: payload,
        });
    }

    function setNotificationFetch(payload) {
        dispatch({
            type: 'NOTIFICATION_FETCH',
            payload: payload,
        });
    }

    return {
        data: state.data,
        isLoading: state.isLoading,
        notificationFetch: state.notificationFetch,
    };
}

export default useGetHourLogEmployeeEditData;
