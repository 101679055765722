import React, { useState } from 'react';
import SystemDataAPI from '../api/system-data/SystemDataAPI';
import axios from 'axios';
import AuthAPI from '../api/auth/AuthAPI';
import { LOCAL_STORAGE_TOKEN_PATH } from '../constants';

const SystemDataContext = React.createContext();

const SystemDataProvider = function (props) {
    const [data, setData] = useState({
        versionNumber: 0,
        employmentAgencies: [],
        locations: [],
        productGroups: [],
        typeOfWorks: [],
    });
    const [isLoading, setIsloading] = useState(true);

    function fetchAllData() {
        AuthAPI.check()
            .then((payload) => {
                if (payload?.data?.success) {
                    doFetchAllData();
                } else {
                    AuthAPI.refresh()
                        .then((payload) => {
                            localStorage.setItem(
                                LOCAL_STORAGE_TOKEN_PATH,
                                payload.data.token
                            );

                            doFetchAllData();
                        })
                        .catch((err) => {
                            localStorage.removeItem(LOCAL_STORAGE_TOKEN_PATH);
                            window.location.reload();
                        });
                }
            })
            .catch((err) => {
                localStorage.removeItem(LOCAL_STORAGE_TOKEN_PATH);
                window.location.reload();
            });
    }

    function doFetchAllData() {
        axios
            .all([
                SystemDataAPI.fetchApplicationVersion(),
                SystemDataAPI.fetchEmploymentAgencies(),
                SystemDataAPI.fetchLocations(),
                SystemDataAPI.fetchProductGroups(),
                SystemDataAPI.fetchTypeOfWorks(),
            ])
            .then(
                axios.spread(
                    (
                        versionNumber,
                        employmentAgencies,
                        locations,
                        productGroups,
                        typeOfWorks
                    ) => {
                        setData({
                            versionNumber: versionNumber.data.data.version,
                            employmentAgencies: employmentAgencies.data.data,
                            locations: locations.data.data,
                            productGroups: productGroups.data.data,
                            typeOfWorks: typeOfWorks.data.data,
                        });
                        setIsloading(false);
                    }
                )
            )
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <SystemDataContext.Provider
            value={{
                fetchAllData,
                isLoadingSystemData: isLoading,
                versionNumber: data.versionNumber,
                employmentAgencies: data.employmentAgencies,
                locations: data.locations,
                productGroups: data.productGroups,
                typeOfWorks: data.typeOfWorks,
            }}
        >
            {props.children}
        </SystemDataContext.Provider>
    );
};

const SystemDataConsumer = SystemDataContext.Consumer;

export { SystemDataProvider, SystemDataConsumer, SystemDataContext };
