import React from 'react';
import { ClipLoader } from 'react-spinners';

function ButtonPrimary(props) {
    let variantStyle = '';

    switch (props.variant) {
        case 'danger':
            variantStyle = 'bg-red-600 hover:bg-red-700 disabled:opacity-60';
            break;
        case 'success':
            variantStyle =
                'bg-green-600 hover:bg-green-700 disabled:opacity-60';
            break;
        case 'primary':
        default:
            variantStyle =
                'bg-primary-400 hover:bg-primary-600 disabled:opacity-60';
            break;
    }

    return (
        <button
            className={`btn max-h-8 ${variantStyle} text-white text-md px-3 py-1 rounded ${
                props.disabled && 'cursor-not-allowed'
            }`}
            type={props.type}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}{' '}
            {props.isLoading ? <ClipLoader color={'white'} size={17} /> : null}
        </button>
    );
}

ButtonPrimary.defaultProps = {
    variant: 'primary',
    isLoading: false,
    type: 'button',
};

export default ButtonPrimary;
