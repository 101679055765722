import React, {useState} from 'react';
import Modal from '../../../../../../components/modal';
import TypeOfWorkAPI from '../../../../../../api/type-of-work/TypeOfWorkAPI';
import Notification from "../../../../../../components/notification";

function TypeOfWorkDelete({ selectedTypeOfWork, setOpen, open, deleteItem, setNotificationParent }) {
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });
    function handleSubmit() {
        TypeOfWorkAPI.delete(selectedTypeOfWork.id)
            .then(() => {
                deleteItem(selectedTypeOfWork.id);

                // show notification (deleted successfully!)
                setNotificationParent({ message: `Werksoort  ${selectedTypeOfWork.description} is verwijderd`, variant: 'success', visible: true, error: {} });

                setOpen(false);
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotification({ message: `Er is iets misgegaan bij het verwijderen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    return (
        <Modal
            title={'Werksoort verwijderen'}
            open={open}
            setOpen={setOpen}
            confirmAction={handleSubmit}
            confirmText={'Verwijderen'}
            variant={'danger'}
        >
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                              onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}
            <p className="mb-4">
                Weet u zeker, dat productgroep{' '}
                <strong>{selectedTypeOfWork.description}</strong> verwijderd kan
                worden.
            </p>
            <p>
                Gekoppelde gegevens, zoals geschreven werksoorten, zijn niet
                meer zichtbaar.
            </p>
        </Modal>
    );
}

export default TypeOfWorkDelete;
