import React, {useEffect, useState} from 'react';
import useGetHourLogEmployeeEditData from '../../../../hooks/useGetHourLogEmployeeEditData';
import LoadingComponent from '../../../../components/loading-component';
import HourLogAPI from '../../../../api/hour-log/HourLogAPI';
import EmployeeHourLogForm from '../default-form';
import WrapperDefaultEmployee from '../../../../components/wrapper-default/employee';
import { HOURLOG_STATUS_APPROVED } from '../../../../constants';
import NotFound from '../../../not-found';
import Notification from "../../../../components/notification";

function EmployeeHourLogEdit({ match: { params }, history }) {
    const { data, isLoading, notificationFetch } = useGetHourLogEmployeeEditData(params.id);
    const [notification, setNotification] = useState({ message: '', variant: '', visible: false, error: {} });

    useEffect(() => {
        setNotification(notificationFetch);
    }, [notificationFetch]);

    function handleSubmit(values, setSubmittingDone) {
        HourLogAPI.update(values)
            .then((payload) => {
                history.push('/employee');
            })
            .catch((error) => {
                console.log(error);
            });
        setSubmittingDone();
    }

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (data.status === HOURLOG_STATUS_APPROVED) {
        return <NotFound />;
    }

    return (
        <WrapperDefaultEmployee>
            {notification.visible && (
                <Notification message={notification.message} variant={notification.variant} error={notification.error}
                              onClose={() => setNotification({message: '', variant: '', visible: false, error: {} } )}/>
            )}

            <EmployeeHourLogForm
                initialValues={data}
                handleSubmit={handleSubmit}
            />
        </WrapperDefaultEmployee>
    );
}

export default EmployeeHourLogEdit;
