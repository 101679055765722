import * as Yup from 'yup';

function getValidationSchemaEmployee() {
    return Yup.object({
        name: Yup.string().required('Verplicht'),
        email: Yup.string().email('Ongeldig e-mailadres').required('Verplicht'),
        locationId: Yup.string().required('Verplicht'),
        employmentAgencyId: Yup.string().required('Verplicht'),
        hourRate: Yup.number()
            .typeError('Ongeldig nummer')
            .moreThan(-1, 'Geen negative nummers')
            .required('Verplicht'),
        schedule: Yup.object({
            monday: Yup.number()
                .typeError('Ongeldig nummer')
                .moreThan(-1, 'Geen negative nummers')
                .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                .required('Verplicht'),
            tuesday: Yup.number()
                .typeError('Ongeldig nummer')
                .moreThan(-1, 'Geen negative nummers')
                .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                .required('Verplicht'),
            wednesday: Yup.number()
                .typeError('Ongeldig nummer')
                .moreThan(-1, 'Geen negative nummers')
                .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                .required('Verplicht'),
            thursday: Yup.number()
                .typeError('Ongeldig nummer')
                .moreThan(-1, 'Geen negative nummers')
                .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                .required('Verplicht'),
            friday: Yup.number()
                .typeError('Ongeldig nummer')
                .moreThan(-1, 'Geen negative nummers')
                .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                .required('Verplicht'),
            saturday: Yup.number()
                .typeError('Ongeldig nummer')
                .moreThan(-1, 'Geen negative nummers')
                .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                .required('Verplicht'),
            sunday: Yup.number()
                .typeError('Ongeldig nummer')
                .moreThan(-1, 'Geen negative nummers')
                .lessThan(25, 'Kan niet meer zijn dan 24 uur')
                .required('Verplicht'),
        }),
    });
}

export default getValidationSchemaEmployee;
