import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function ToggleLanguage() {
    const { i18n } = useTranslation();
    const languageOptions = ['en', 'nl', 'pl'];

    function changeLanguage(lang) {
        i18n.changeLanguage(lang);
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="inline-flex justify-center content-center w-full px-2 sm:px-3 py-1 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 uppercase">
                            <span className="text-xs sm:text-base">
                                {i18n.language.split('-')[0]}
                            </span>
                            <FaChevronDown
                                className="w-3 h-3 m-1 ml-2 -mr-1 hidden sm:block"
                                size={12}
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                                {languageOptions.map((language) => (
                                    <Menu.Item key={language}>
                                        {({ active }) => (
                                            <button
                                                className={`${
                                                    active
                                                        ? 'bg-primary-400 text-white'
                                                        : 'text-gray-900'
                                                } group flex rounded-md items-center w-full px-2 py-1 text-sm uppercase`}
                                                onClick={() =>
                                                    changeLanguage(language)
                                                }
                                            >
                                                {language}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
}

export default ToggleLanguage;
