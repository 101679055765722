import React from 'react';
import HourLogEmployeeTableRow from './row';

function HourLogEmployeeTable({
    hourLogs,
    updateOrCreate,
    deleteItem,
    date,
    employeeId,
    setNotificationParent,
}) {
    return (
        <table className="w-full">
            <thead>
                <tr className="text-left font-semibold text-gray-600 border-b border-gray-200">
                    <th className="p-2 w-60">Productgroep</th>
                    <th className="p-2 w-60">Werksoort</th>
                    <th className="p-2 w-52">Locatie</th>
                    <th className="p-2 w-60">Opmerking</th>
                    <th className="p-2 w-24">Seizoen</th>
                    <th className="p-2 w-28">Uren</th>
                    <th className="p-2 w-28">Aantal</th>
                    <th className="p-2 w-20">Actie</th>
                </tr>
            </thead>
            <tbody>
                {hourLogs.map((item) => (
                    <HourLogEmployeeTableRow
                        key={item.id}
                        item={item}
                        updateOrCreate={updateOrCreate}
                        deleteItem={deleteItem}
                        date={date}
                        employeeId={employeeId}
                        setNotificationParent={setNotificationParent}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default HourLogEmployeeTable;
