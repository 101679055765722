import React from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import ProtectedRouteEmployee from './ProtectedRouteEmployee';
import ProtectedRouteManager from './ProtectedRouteManager';
import ProtectedRouteDefaultPathRedirector from './ProtectedRouteDefaultPathRedirector';
import { useTranslation } from 'react-i18next';

import Dashboard from '../container/manager/dashboard';
import Login from '../container/authorization/login';
import Logout from '../container/authorization/logout';
import NotFound from '../container/not-found';
import EmployeeHourLogList from '../container/employee/hour-log/list';
import EmployeeHourLogNew from '../container/employee/hour-log/new';
import EmployeeHourLogEdit from '../container/employee/hour-log/edit';
import EmployeeList from '../container/manager/employee/list';
import HourLogOverview from '../container/manager/hour-log/overview';
import HourLogEmployee from '../container/manager/hour-log/employee';
import Reset from '../container/authorization/reset';
import Forgot from '../container/authorization/forgot';
import ProductGroupList from '../container/manager/settings/product-group/list';
import TypeOfWorkList from '../container/manager/settings/type-of-work/list';
import ManagerList from '../container/manager/managers/list';
import LocationList from '../container/manager/settings/location/list';

function RouteSwitch() {
    const { t } = useTranslation();

    return (
        <Router onUpdate={() => window.scrollTo(0, 0)}>
            <Switch>
                {/* Protected routes default path redirector */}
                <ProtectedRouteDefaultPathRedirector path="/" exact={true} />

                {/* Protected routes manager */}
                <ProtectedRouteManager
                    path="/manager"
                    component={Dashboard}
                    title={'Dashboard'}
                />
                <ProtectedRouteManager
                    path="/medewerkers"
                    component={EmployeeList}
                    title={'Overzicht medewerkers'}
                />
                <ProtectedRouteManager
                    path="/managers"
                    component={ManagerList}
                    title={'Overzicht managers'}
                />
                <ProtectedRouteManager
                    exact
                    path="/overzicht-uren"
                    component={HourLogOverview}
                    title={'Overzicht uren'}
                />
                <ProtectedRouteManager
                    path="/overzicht-uren/medewerker/:employeeId/:year/:week"
                    component={HourLogEmployee}
                    title={'Overzicht uren medewerker'}
                />
                <ProtectedRouteManager
                    path="/instellingen/locaties"
                    component={LocationList}
                    title={'Locaties'}
                />
                <ProtectedRouteManager
                    path="/instellingen/productgroepen"
                    component={ProductGroupList}
                    title={'Productgroepen'}
                />
                <ProtectedRouteManager
                    path="/instellingen/werksoorten"
                    component={TypeOfWorkList}
                    title={'Werksoorten'}
                />

                {/* Protected routes employee */}
                <ProtectedRouteEmployee
                    path="/employee"
                    exact={true}
                    component={EmployeeHourLogList}
                    title={t('pages.employee_hour_log_list')}
                />
                <ProtectedRouteEmployee
                    path="/employee/hourlog/new"
                    component={EmployeeHourLogNew}
                    title={t('pages.employee_hour_log_new')}
                />
                <ProtectedRouteEmployee
                    path="/employee/hourlog/edit/:id"
                    component={EmployeeHourLogEdit}
                    title={t('pages.employee_hour_log_edit')}
                />
                {/* Public routes */}
                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/logout" component={Logout} />
                <PublicRoute
                    path="/password/reset/:token/:email"
                    component={Reset}
                />
                <PublicRoute path="/password-forgotten" component={Forgot} />
                <PublicRoute component={NotFound} />
            </Switch>
        </Router>
    );
}

export default RouteSwitch;
