import React from 'react';
import { useHistory } from 'react-router';
import { IoMdArrowBack } from 'react-icons/io';

function WrapperDefaultManager({ children, showBackButton }) {
    const history = useHistory();

    return (
        <>
            <div className="py-2">
                {showBackButton && (
                    <span
                        className="flex w-20 p-2 text-gray-500 text-lg cursor-pointer hover:bg-gray-200 rounded-lg"
                        onClick={() => history.goBack()}
                    >
                        <IoMdArrowBack />
                        <span className="text-sm uppercase">Terug</span>
                    </span>
                )}
            </div>
            <div className="w-full p-4 bg-white rounded shadow">{children}</div>
        </>
    );
}

WrapperDefaultManager.defaultProps = {
    showBackButton: true,
};

export default WrapperDefaultManager;
