import React from 'react';
import TableDefault from '../../../../../components/table/default';

function LocationListTable({
    data,
    openEditLocationModal,
    openDeleteLocationModal,
    handleToggleActiveState,
}) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: 'code',
                width: '20%',
            },
            {
                Header: 'Omschrijving',
                accessor: 'description',
                width: '80%',
            },
        ],

        []
    );

    function handleOnClickEdit(rowData) {
        openEditLocationModal(rowData);
    }

    function handleOnClickDelete(rowData) {
        openDeleteLocationModal(rowData);
    }

    //TODO PK verwijderen:
    // function handleOnClickToggleActive(rowData) {
    //     handleToggleActiveState(rowData);
    // }

    return (
        <TableDefault
            columns={columns}
            data={data}
            initialState={{ sortBy: [{ id: 'code', desc: false }] }}
            handleOnClickEdit={handleOnClickEdit}
            handleOnClickDelete={handleOnClickDelete}
        />
    );
}

export default LocationListTable;
