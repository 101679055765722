import React from 'react';
import { withRouter } from 'react-router-dom';
import ButtonTertiary from './ButtonTertiary';

const ButtonLinkTertiary = (props) => {
    const {
        history,
        location,
        match,
        staticContext,
        to,
        onClick,
        // ⬆ filtering out props that `button` doesn’t know what to do with.
        ...rest
    } = props;
    return (
        <ButtonTertiary
            {...rest}
            onClick={(event) => {
                onClick && onClick(event);
                history.push(to);
            }}
        />
    );
};

export default withRouter(ButtonLinkTertiary);
