import React from 'react';
import TableDefault from '../../../../../components/table/default';

function ProductGroupListTable({
    data,
    openEditProductGroupModal,
    openDeleteProductGroupModal,
    handleToggleActiveState,
}) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Omschrijving',
                accessor: 'description',
                width: '30%',
            },
            {
                Header: 'Omschrijving Engels',
                accessor: 'descriptionEn',
                width: '30%',
            },
            {
                Header: 'Omschrijving Pools',
                accessor: 'descriptionPl',
                width: '30%',
            },
            {
                Header: 'Actief',
                accessor: 'active',
                width: '8%',
                Cell: ({ row, cell: { value } }) => {
                    return value ? 'Ja' : 'Nee';
                },
            },
        ],

        []
    );

    function handleOnClickEdit(rowData) {
        openEditProductGroupModal(rowData);
    }

    function handleOnClickDelete(rowData) {
        openDeleteProductGroupModal(rowData);
    }

    function handleOnClickToggleActive(rowData) {
        handleToggleActiveState(rowData);
    }

    return (
        <TableDefault
            columns={columns}
            data={data}
            initialState={{ sortBy: [{ id: 'description', desc: false }] }}
            handleOnClickEdit={handleOnClickEdit}
            handleOnClickDelete={handleOnClickDelete}
            handleOnClickToggleActive={handleOnClickToggleActive}
        />
    );
}

export default ProductGroupListTable;
