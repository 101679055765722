import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ButtonTertiaryLink from '../../../components/button/ButtonTertiaryLink';
import InputTextSet from '../../../components/form/InputTextSet';
import InputPasswordSet from '../../../components/form/InputPasswordSet';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../../../components/button/ButtonGroup';

function LoginForm({ handleSubmit }) {
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email(t('form_general.invalid_email_address'))
                    .required(t('form_general.required')),
                password: Yup.string().required(t('form_general.required')),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <InputTextSet
                            label={t('authentication.login.email_label')}
                            name="email"
                            placeholder={t(
                                'authentication.login.email_placeholder'
                            )}
                        />
                        <InputPasswordSet
                            label={t('authentication.login.password_label')}
                            name="password"
                            placeholder={t(
                                'authentication.login.password_placeholder'
                            )}
                        />

                        <ButtonGroup>
                            <ButtonTertiaryLink to={'/password-forgotten'}>
                                {t(
                                    'authentication.login.password_forgotten_button'
                                )}
                            </ButtonTertiaryLink>
                            <ButtonPrimary
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                type={'submit'}
                            >
                                {t('button.login')}
                            </ButtonPrimary>
                        </ButtonGroup>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default LoginForm;
