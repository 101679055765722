import { useEffect, useReducer } from 'react';
import HourLogAPI from '../api/hour-log/HourLogAPI';


const INITIAL_STATE = {
    data: { employee: {}, details: {}, week: [] },
    isLoading: false,
    notificationFetch: {message: '', variant: '', visible: false, error: {}}
};

const INITIAL_SINGLE_HOURLOG = {
    id: null,
    locationId: '',
    productGroupId: '',
    typeOfWorkId: '',
    period: '-',
    amount: null,
    typeOfWorkAmount: null,
    description: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'RESULT':
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case 'CREATE_EMPTY_HOUR_LOG':
            return {
                ...state,
                data: {
                    ...state.data,
                    week: state.data.week.map((item) => {
                        if (item.date === action.payload) {
                            return {
                                ...item,
                                hourLogs: [
                                    ...item.hourLogs,
                                    INITIAL_SINGLE_HOURLOG,
                                ],
                            };
                        }

                        return item;
                    }),
                },
                isLoading: false,
            };
        case 'DELETE_HOUR_LOG':
            return {
                ...state,
                data: {
                    ...state.data,
                    week: state.data.week.map((item) => {
                        if (item.date === action.payload.date) {
                            return {
                                ...item,
                                hourLogs: [
                                    ...item.hourLogs.filter((hourLog) => {
                                        return hourLog.id !== action.payload.id;
                                    }),
                                ],
                            };
                        }

                        return item;
                    }),
                },
                isLoading: false,
            };
        case 'IS_LOADING':
            return { ...state, isLoading: action.payload };
        case 'NOTIFICATION_FETCH':
            return { ...state, notificationFetch: action.payload };
        default:
            return state;
    }
};

function useGetHourLogsEmployeeWeekOverviewData(employeeId, week, year) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [employeeId, week, year]);

    function fetchData() {
        HourLogAPI.employeeWeekOverview({
            employeeId,
            week,
            year,
        })
            .then((payload) => {
                setResult(payload.data.data);
            })
            .catch((error) => {
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het ophalen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
                setIsLoading(false);
            });
    }

    function setIsLoading(payload) {
        dispatch({
            type: 'IS_LOADING',
            payload: payload,
        });
    }

    function setResult(payload) {
        dispatch({
            type: 'RESULT',
            payload: payload,
        });
    }

    function approveHours() {
        HourLogAPI.approve({
            hourLogs: getIdsOfHourlogs(),
        })
            .then((payload) => {
                fetchData();
                setNotificationFetch({ message: `De uren zijn succesvol goedgekeurd.`, variant: 'success', visible: true, error: {} });
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het ophalen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    function rejectHours() {
        HourLogAPI.reject({
            hourLogs: getIdsOfHourlogs(),
        })
            .then((payload) => {
                fetchData();
                setNotificationFetch({ message: `De uren zijn succesvol afgekeurd.`, variant: 'success', visible: true, error: {} });
            })
            .catch((error) => {
                console.log(error);
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het ophalen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    function createEmptyHourLog(date) {
        dispatch({
            type: 'CREATE_EMPTY_HOUR_LOG',
            payload: date,
        });
    }

    function updateOrCreate(values, callback) {
        if (values.id === null) {
            create(values, callback);
        } else {
            update(values, callback);
        }
    }

    function create(values, callback) {
        HourLogAPI.create(values)
            .then((payload) => {
                fetchData();

                setNotificationFetch({ message: `De uren zijn succesvol toegevoegd.`, variant: 'success', visible: true, error: {} });

                callback();
            })
            .catch((error) => {
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het toevoegen van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    function update(values, callback) {
        HourLogAPI.update(values)
            .then((payload) => {
                fetchData();
                setNotificationFetch({ message: `De aanpassing op de uren zijn succesvol verwerkt.`, variant: 'success', visible: true, error: {} });

                callback();
            })
            .catch((error) => {
                // show notification (error!)
                setNotificationFetch({ message: `Er is iets misgegaan bij het verwerken van de gegevens. Probeer het nogmaals, of neem contact met ons op.`, variant: 'error', visible: true, error: error });
            });
    }

    function deleteHourLog(date, id) {
        dispatch({
            type: 'DELETE_HOUR_LOG',
            payload: {
                date: date.format('YYYY-MM-DD'),
                id: id,
            },
        });
    }

    function getIdsOfHourlogs() {
        let result = [];

        state.data.week.forEach((a) => {
            a.hourLogs.forEach((b) => {
                result.push(b.id);
            });
        });

        return result;
    }


    function setNotificationFetch(payload) {
        dispatch({
            type: 'NOTIFICATION_FETCH',
            payload: payload,
        });
    }
    return {
        data: state.data,
        isLoading: state.isLoading,
        notificationFetch: state.notificationFetch,
        approveHours,
        rejectHours,
        createEmptyHourLog,
        updateOrCreate,
        deleteHourLog,
    };
}

export default useGetHourLogsEmployeeWeekOverviewData;
