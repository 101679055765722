import React from 'react';
import LogoImage from '../../images/Logo_Darthuizer_Groep.svg';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <section>
            <img src={LogoImage} alt="Logo Darthuizer Groep" />
            <div>
                <h1>
                    <strong>404:</strong> pagina niet gevonden!
                </h1>
                <p>
                    Helaas kunnen we de pagina niet vinden. Dit kan komen
                    doordat de pagina niet meer bestaat, of doordat er een
                    typefout is gemaakt bij het invoeren van het adres.
                </p>
                <p>
                    Ga terug naar de <Link to={'/'}>hoofdpagina</Link>
                </p>
            </div>
        </section>
    );
}

export default NotFound;
