import React from 'react';
import { useField } from 'formik';
import ErrorMessage from './parts/ErrorMessage';
import Label from './parts/Label';
import InputPassword from './parts/InputPassword';

function InputPasswordSet({ label, required, autoComplete, ...props }) {
    const [field, meta] = useField(props);

    return (
        <div className="w-full mb-3">
            <Label htmlFor={field.id || field.name} required={required}>
                {label}
            </Label>
            <InputPassword {...props} autoComplete={autoComplete} />
            {meta.touched && meta.error ? (
                <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
        </div>
    );
}

InputPasswordSet.defaultProps = {
    required: false,
    autoComplete: 'off',
};

export default InputPasswordSet;
