import * as Yup from 'yup';

function getValidationSchemaProductGroup() {
    return Yup.object({
        code: Yup.string().required('Verplicht'),
        description: Yup.string().required('Verplicht'),
        descriptionEn: Yup.string().required('Verplicht'),
        descriptionPl: Yup.string().required('Verplicht'),
    });
}

export default getValidationSchemaProductGroup;
