import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../src/locales/en.json';
import translationNL from '../src/locales/nl.json';
import translationPL from '../src/locales/pl.json';

const resources = {
    en: {
        translation: translationEN,
    },
    nl: {
        translation: translationNL,
    },
    pl: {
        translation: translationPL,
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
        debug: false,

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
