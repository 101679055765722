import React, { useState } from 'react';
import HourLogEmployeeTableRowView from './View';
import HourLogEmployeeTableRowEdit from './Edit';
import HourLogDelete from './Delete';

function HourLogEmployeeTableRow({
    item,
    updateOrCreate,
    deleteItem,
    date,
    employeeId,
    setNotificationParent,
}) {
    const [showEdit, setShowEdit] = useState(item.id === null);
    const [showDelete, setShowDelete] = useState(item.id === null);

    function toggleShowEdit() {
        setShowEdit(!showEdit);
    }

    function toggleShowDelete() {
        setShowDelete(!showDelete);
    }

    if (showEdit) {
        return (
            <>
                <HourLogEmployeeTableRowEdit
                    initialValues={item}
                    toggleShowEdit={toggleShowEdit}
                    toggleShowDelete={toggleShowDelete}
                    updateOrCreate={updateOrCreate}
                    date={date}
                    employeeId={employeeId}
                />
                {showDelete && item.id !== null && (
                    <HourLogDelete
                        id={item.id}
                        date={date}
                        open={showDelete}
                        setOpen={toggleShowDelete}
                        deleteItem={deleteItem}
                        setNotificationParent={setNotificationParent}
                    />
                )}
            </>
        );
    } else {
        return (
            <>
                <HourLogEmployeeTableRowView
                    {...item}
                    toggleShowEdit={toggleShowEdit}
                />
            </>
    );
    }
}

export default HourLogEmployeeTableRow;
