import axiosInstance from '../default-setup/AxiosInstance';

export default {
    index: function (payload) {
        const requestUrl = 'locations';

        return axiosInstance.get(requestUrl, payload);
    },

    create: function (payload) {
        const requestUrl = 'locations';

        return axiosInstance.post(requestUrl, payload);
    },

    update: function (payload) {
        const requestUrl = `locations/${payload.id}`;

        return axiosInstance.put(requestUrl, payload);
    },

    delete: function (id) {
        const requestUrl = `locations/${id}`;

        return axiosInstance.delete(requestUrl);
    },
};
